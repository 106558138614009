import {GanttStatic} from 'dhtmlx-gantt';

import {useInlineSelectController} from 'modules/Tasks/components/Gantt/components/Editors/useInlineSelectController';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import Select from 'shared/components/CoreForm/Select/Select';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import useZones from 'shared/hooks/useZones';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
};

type ZoneUpdateFields = {
  wc_zone_id: number | null;
  wc_space_id: number | null;
};

const ZoneSelector = ({gantt, task}: Props) => {
  const {onKeyUp, onKeyDown, onClose, focusOrHide} = useInlineSelectController(gantt);
  const mixpanelEvents = gantt.mixpanel.events.gantt.inlineEdit;
  const {hasAnyAdminRole} = useCompanyWorkerRoles(task.projectId);
  const {data: zones, isLoading} = useZones(task.projectId);

  const getSelectOptions = () => {
    const zoneOptions = zones?.map((zone) => ({
      // Change the value format to just use zone.id
      value: zone.id,
      // Update the label format to match the example
      label: `${zone.zoneName}-${zone.spaceId}`,
    }));

    zoneOptions.sort((a, b) => a.label.localeCompare(b.label));
    return zoneOptions;
  };

  const updateZoneValues = (task: GanttTask, updates: ZoneUpdateFields) => {
    const _task = {...task};
    _task.lastChangedFields = {...task.lastChangedFields};

    Object.entries(updates).forEach(([name, value]) => {
      _task.lastChangedFields[name as keyof ZoneUpdateFields] = {
        newValue: value,
        oldValue: _task[name as keyof ZoneUpdateFields],
      };
    });

    gantt.updateTask(task.id, {..._task, ...updates});
    gantt.mixpanel.track(mixpanelEvents, {viewMode: gantt.name, column: 'zone'});
    focusOrHide();
  };

  const onChange = (selectedOption: string) => {
    if (!selectedOption) {
      updateZoneValues(task, {
        wc_zone_id: null,
        wc_space_id: null,
      });
      return;
    }

    const zoneId = Number(selectedOption);
    const selectedZone = zones?.find((zone) => zone.id === zoneId);

    updateZoneValues(task, {
      wc_zone_id: zoneId,
      wc_space_id: selectedZone?.spaceId || null,
    });
  };

  const getValue = () => {
    if (task.wc_zone_id === undefined) {
      return '';
    }
    return task.wc_zone_id;
  };

  if (!hasAnyAdminRole || !zones?.length) {
    return null;
  }

  return (
    <Select
      closeMenuOnSelect={true}
      isClearable={true}
      isLoading={isLoading}
      isMulti={false}
      isSearchable
      menuIsOpen={true}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      options={getSelectOptions()}
      tabSelectsValue={false}
      value={getValue()}
    />
  );
};

export default ZoneSelector;
