import classNames from 'classnames';
import {FC, ReactNode} from 'react';

import Popup from 'shared/components/Popup/Popup';
import env from 'shared/constants/env';

type Props = {
  visible: boolean;
  onClose: () => void;
  header: string;
  description?: string;
  buttonText: string;
  children: ReactNode;
};

import Button from '../Button';

import styles from './tutorialPopup.module.scss';

export const TutorialPopup: FC<Props> = ({
  visible,
  onClose,
  header,
  description,
  buttonText = 'Finish',
  children,
}: Props) => {
  if (env.cypress) {
    return null;
  }
  return (
    <Popup visible={visible} onClose={onClose} className="popup__item--size-m">
      <Popup.Body className={styles.tutorial__body}>
        <form className="form-onboarding-z form-onboarding-z--without-nav form-onboarding-z--without-footer">
          <div className="form-onboarding-z__container">
            <header className="form-onboarding-z__header">
              <h1 className="form-onboarding-z__title">{header}</h1>
              {description && <div className="form-onboarding-z__description">{description}</div>}
            </header>
            <div className="form-onboarding-z__grid">
              <div className="form-onboarding-z__item">
                <div className={classNames('tutorial__container', styles.tutorial__container)}>{children}</div>
              </div>
            </div>
          </div>
        </form>
      </Popup.Body>
      <Popup.Footer>
        <Button className="popup__button" colorStyle="success" onClick={onClose}>
          {buttonText}
        </Button>
      </Popup.Footer>
    </Popup>
  );
};
