import classnames from 'classnames';
import {FC, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'shared/components/Button';
import ExternalLink from 'shared/components/ExternalLink';
import Icon from 'shared/components/Icon';
import Popup from 'shared/components/Popup/Popup';
import {useOutsideClick} from 'shared/hooks/core/useOusideClick';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

import {HeaderControls} from '../Header/useHeaderControls';

const WidgetMenu: FC<HeaderControls> = ({isOpen, open, close}) => {
  const [visible, setVisible] = useState(false);
  const popupInformer = useRef<HTMLDivElement>();
  const {
    mixpanel: {events: mixpanelEvents, ...mixpanel},
  } = useAnalyticsService();
  const {t} = useTranslation('left_menu');

  useOutsideClick({ref: popupInformer, callback: close});

  return (
    <div
      className="header__informer"
      onClick={() => mixpanel.trackWithAction(open, mixpanelEvents.common.buttons.haveQuestions)}
      ref={popupInformer}
    >
      <div className={classnames('info-alert', 'info-alert--help', 'header__alert', {'is-active': isOpen})}>
        <div className="info-alert__container">
          <div className="info-alert__header">
            <div className="info-alert__title">{t('widget.title', 'Have questions?')}</div>
            <Button iconOnly className=" info-alert__button-toggle" icon={<Icon name="help_outlined" colorFill />}>
              {t('widget.buttons.help', 'Toggle Help')}
            </Button>
          </div>
          <div className="info-alert__body">
            <ul className="info-alert__list">
              <li className="info-alert__list-item">
                <Icon colorFill className="info-alert__list-icon" name="arrow-right" size={24} />
                <span className="info-alert__list-text">
                  {t('widget.video.title_1', 'Watch tutorial video')}{' '}
                  <a style={{cursor: 'pointer'}} onClick={() => setVisible(true)}>
                    {t('widget.video.title_2', 'here')}
                  </a>
                </span>
              </li>
              <li className="info-alert__list-item">
                <Icon colorFill className="info-alert__list-icon" name="arrow-right" size={24} />
                <span className="info-alert__list-text">
                  {t('widget.faq.title', 'Check our')}{' '}
                  <ExternalLink
                    target="_blank"
                    href="https://kb.bycore.com/knowledge"
                    text={t('widget.faq.text', 'FAQs')}
                  />
                </span>
              </li>
              <li className="info-alert__list-item">
                <Icon colorFill className="info-alert__list-icon" name="arrow-right" size={24} />
                <span className="info-alert__list-text">
                  <a href="mailto:c4@bycore.com?subject=Help Importing Activities">
                    {t('widget.help.title', 'Get help')}
                  </a>{' '}
                  {t('widget.help.activities', 'Importing Activities')}
                </span>
              </li>
              <li className="info-alert__list-item">
                <Icon colorFill className="info-alert__list-icon" name="arrow-right" size={24} />
                <span className="info-alert__list-text">
                  {t('widget.contacts.title', 'Or contact us by')}{' '}
                  <a href="mailto:c4@bycore.com?subject=Question about Crews by Core Pro / C4">
                    {t('widget.contacts.email', 'email')}
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <Popup visible={visible} onClose={() => setVisible(false)} className="popup__item--video">
          <div className="video popup__video">
            <iframe
              className="video__frame"
              title="vimeo-player"
              src="https://player.vimeo.com/video/537502901?autoplay=true&loop=true"
              width="640"
              height="360"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default WidgetMenu;
