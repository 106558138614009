import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import {useRef, useEffect, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {TasksLocationState} from 'modules/Tasks/types/location';
import Loader from 'shared/components/Loader';

import ChatTimelinePlaceholder from '../../ChatTimeline/EmptyChatTimeline/ChatTimelinePlaceholder';

import {CommentCard, CommentCardBody, CommentCardSeparator} from './CommentCard';
import {useCommentsFeed} from './hooks/useCommentsFeed';
import s from './styles.module.scss';

type CommentsListProps = {
  projectId?: string;
  taskId: string;
  issueId?: string;
};

export const CommentsList = observer(function CommentList({projectId, taskId, issueId}: CommentsListProps) {
  const history = useHistory<TasksLocationState>();
  const {t} = useTranslation('comments');
  const {
    comments,
    isLoading: commentsLoading,
    isFetching,
    hasNoComments,
  } = useCommentsFeed({projectId, taskId, issueId});
  const endOfList = useRef<HTMLDivElement>(null);
  let currentDate = null;

  useEffect(() => {
    const scrollToElement = () => {
      let timeoutId: NodeJS.Timeout;
      if (history.location.state?.eventId) {
        const element = document.querySelector(`[data-matrix-event-id="${history.location.state?.eventId}"]`);
        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'center'});
          element.classList.add(s['blinking']);

          timeoutId = setTimeout(() => {
            element.classList.remove(s['blinking']);
          }, 3000);

          return;
        }
      }

      if (history.location.state?.dailyReportDate) {
        const el = document.getElementById(history.location.state?.dailyReportDate);
        if (el) {
          el.scrollIntoView({behavior: 'smooth'});
          return;
        }
      }

      // Scroll to the end if no specific element is found
      if (endOfList.current) {
        endOfList.current.scrollIntoView({behavior: 'smooth', block: 'end'});
      }

      return () => {
        clearTimeout(timeoutId);
      };
    };

    if (comments.length > 0 && !commentsLoading) {
      scrollToElement();
    }
  }, [comments, commentsLoading, history]);

  if (commentsLoading || isFetching) {
    return <Loader />;
  }

  if (hasNoComments) {
    return (
      <ChatTimelinePlaceholder
        title={t('comments_list.comments.empty.title')}
        description={t('comments_list.comments.empty.description')}
      />
    );
  }

  return (
    <>
      {comments?.map((commentContent, index) => {
        const commentItemsDateFormatted = dayjs(commentContent.date).format('MMMM D, YYYY');
        const showDateSeparator = commentItemsDateFormatted !== currentDate;
        currentDate = commentItemsDateFormatted;
        const dayValue = dayjs(commentItemsDateFormatted).valueOf().toString();

        return (
          <Fragment key={commentContent.key}>
            {showDateSeparator ? (
              <>
                {currentDate}
                <CommentCardSeparator forceDisplay />
              </>
            ) : null}
            <CommentCard key={commentContent.key + commentContent.date} day={dayValue}>
              <CommentCardBody commentContent={commentContent} taskId={taskId} />
            </CommentCard>
            {/* keep list scrolled to the bottom when opening the list of comments when not navigating from dailies view */}
            {index === comments?.length - 1 ? <div ref={endOfList} /> : null}
          </Fragment>
        );
      })}
    </>
  );
});
