import * as Sentry from '@sentry/browser';
import {GanttStatic} from 'dhtmlx-gantt';
import {TFunction} from 'i18next';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {reactQueryClient} from 'App';
import {container} from 'IocContainer';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {DataProcessorAction} from 'modules/Tasks/components/Gantt/utils/constants';
import {prepareTaskChangedFieldsForUpdate, prepareTaskForCreate} from 'modules/Tasks/components/Gantt/utils/functions';
import {moveTask} from 'modules/Tasks/utils/asyncHelpers';
import {refreshTask} from 'modules/Tasks/utils/functions';
import {GanttSimpleLock} from 'modules/Tasks/utils/simpleLock';
import {updateAffectedTasksVariance, updateTaskVariance} from 'modules/Tasks/Views/Gantt/utils/baselineHandlers';
import {TaskUpdateFifoQueue} from 'services/TaskUpdateFifoQueue';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {toShortIso} from 'shared/helpers/dates';
import {GanttTaskColors, mapGanttLinkTypeToTaskDepType} from 'shared/helpers/task';
import {QueryCacheHelper} from 'shared/hooks/useQueryCache/QueryCacheHelper';
import {
  ganttToTaskModelRawDTO,
  taskWithAffectedToArray,
  toGanttTaskModel,
  toTaskModelRawDTO,
} from 'shared/mapping/task';
import {IOC_TYPES} from 'shared/models/ioc';
import {ProjectModel} from 'shared/models/project';
import {TaskObjectType, TaskProjection} from 'shared/models/task/const';
import {IssueModel} from 'shared/models/task/issue';
import {GanttLinkModel, TaskModelRawDTO, TaskDetailsModelDTO, GanttLinkModelDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {Worker} from 'shared/models/worker';
import {expandCreate, expandUpdate} from 'shared/prediction/expander';
import {getMemoizedCalendar, prepareLastChangedFields} from 'shared/prediction/utils';
import {GanttStoreType} from 'shared/stores/GanttStore';
import {TasksStoreType} from 'shared/stores/TasksStore';
import {getIndexInParentFromOSK} from 'shared/stores/utils/calculations';
import {applyChangeset} from 'shared/stores/utils/transforms';
import {RootDispatch} from 'store';
import {getProject} from 'store/projects/actions';

// this function checks if use changed some dates related fields
// after task creation we hide predefined dates from user until he touch or change it
function checkTaskHasPristineDates(task: GanttTask): boolean {
  return (
    !task?.lastChangedFields ||
    !['taskDuration', 'duration', 'startDate', 'endDate'].some((key) => task.lastChangedFields.hasOwnProperty(key))
  );
}

export interface DataProcessorConfig {
  gantt: GanttStatic;
  project: ProjectModel;
  linkToDelete: React.MutableRefObject<GanttLinkModel>;
  colors?: GanttTaskColors;
  cacheHelper?: QueryCacheHelper;
  dispatch: RootDispatch;
  t: TFunction;
}

/**
 * Determine the correct index in an unfiltered list to add task.
 *
 * A temporary outline_sort_key will be generated based on where the task
 * needs to be in an unfiltered list, but the Gantt $local_index might
 * not be accurate for an unfiltered list.
 *
 * @param {TaskModelRawDTO[]} tasks - List of all tasks
 * @param {GanttStatic} gantt - Gantt instance the task was added to
 * @param {string} parent - Task ID of parent (WBS) to add to or '0'
 * @param {number} localIndex - 0-based offset within current (potentially filtered) Gantt view
 * @return {number} 0-based index within WBS parent without regard to filtering
 */
function getUnfilteredIndex(tasks: TaskModelRawDTO[], gantt: GanttStatic, parent: string, localIndex: number): number {
  if (localIndex === 0) {
    return 0;
  }
  const children = gantt.getChildren(parent);
  const siblingOSK = gantt.getTask(children[localIndex - 1]).outline_sort_key;
  const siblingGlobalIdx = getIndexInParentFromOSK(tasks, siblingOSK);
  return siblingGlobalIdx + 1;
}

export function createIssuesDataProcessor({gantt, dispatch}: DataProcessorConfig) {
  const dp = gantt.createDataProcessor({
    task: {
      create: () => {
        console.warn('not implemented');
      },
      update: async (_data: TaskDetailsModelDTO, id: string) => {
        // get real task instead of data to avoid serialize errors made by gantt library
        const task = gantt.getTask(id);
        const dataVersion = task.dataVersion;
        if (Object.keys(task.lastChangedFields).length) {
          try {
            const changedFields = prepareTaskChangedFieldsForUpdate(task);
            task.lastChangedFields = {};
            const res = await TasksApi.updateIssue(changedFields);
            reactQueryClient.setQueryData<IssueModel>([QUERY_CACHE_KEYS.issuesPanel, id], res);
            // exclude parent from response, because parent may not satisfy gantt filters and in that case task will disappear
            const {parent, ...updated} = toGanttTaskModel(res);
            if ('location' in changedFields) {
              dispatch(getProject(res.projectId));
            }
            if (!dataVersion || dataVersion >= task.dataVersion) {
              refreshTask(gantt, task.id, updated);
            }
            return res;
          } catch (e) {
            task.lastChangedFields = {};
            return {action: DataProcessorAction.Error};
          }
        }
      },
      delete: () => {
        console.warn('not implemented');
      },
    },
    link: {
      // link object must exist to data processor be valid
      create: () => {
        console.warn('not implemented');
      },
      update: () => {
        console.warn('not implemented');
      },
      delete: () => {
        console.warn('not implemented');
      },
    },
  });
  return dp;
}

export type MobxDataProcessorConfig = {
  gantt: GanttStatic;
  project: ProjectModel;
  profile: Worker;
  projectId: string;
  linkToDelete: React.MutableRefObject<GanttLinkModel>;
  colors?: GanttTaskColors;
  cacheHelper?: QueryCacheHelper;
  dispatch: (action: unknown) => void;
  t: TFunction;
};

export const createDataProcessor = ({
  project,
  gantt,
  profile,
  cacheHelper,
  linkToDelete,
  dispatch,
  t,
}: MobxDataProcessorConfig) => {
  const tasksStore = container.get<TasksStoreType>(IOC_TYPES.TasksStore);
  const ganttStore = container.get<GanttStoreType>(IOC_TYPES.GanttStore);
  const updateFifoQueue = container.get<TaskUpdateFifoQueue>(IOC_TYPES.TaskUpdateFifoQueue);
  const simpleLock = GanttSimpleLock.getInstance(gantt);

  const processor = gantt.createDataProcessor({
    task: {
      update: async (_data: TaskDetailsModelDTO, id: string) => {
        const task = gantt.getTask(id);
        if (!Object.keys(task.lastChangedFields).length) return;

        const apiChangedFields = prepareTaskChangedFieldsForUpdate(task);
        const rawTask = ganttToTaskModelRawDTO(task);
        const cal = getMemoizedCalendar(project);
        const delta = prepareLastChangedFields(task.lastChangedFields);
        const expanded = expandUpdate(cal, rawTask, delta);

        if (checkTaskHasPristineDates(task) === false) {
          task.datesIsPristine = false;
        }

        applyChangeset(tasksStore, expanded);

        updateFifoQueue.enqueue(async (tempIdMap: Map<number, string>) => {
          try {
            // Might be enqueued with tempId, so retrieve the DB id
            const taskId = typeof task.id === 'string' ? task.id : tempIdMap.get(task.id);
            const result = await TasksApi.updateTaskWithAffected({
              ...apiChangedFields,
              id: taskId,
            });

            const tasks = taskWithAffectedToArray(result).map((task) => {
              if (
                apiChangedFields.hasOwnProperty('wcZoneId') &&
                apiChangedFields.wcZoneId === null &&
                !('wc_zone_id' in task)
              ) {
                task.wc_zone_id = null;
                task.wc_space_id = null;
              }
              if (
                apiChangedFields.hasOwnProperty('wcCrewId') &&
                apiChangedFields.wcCrewId === null &&
                !task.hasOwnProperty('wc_crew_id')
              ) {
                task.wc_crew_id = null;
              }
              return task;
            });
            const affectedTaskIds = tasks.map((task) => task.id);

            task.lastChangedFields = {};

            if ('location' in apiChangedFields) {
              /**
               * TODO: Move this into a TasksStore observer. Make sure none of our
               * useEffects trigger [project] in such a way as to reload all the tasks.
               */
              dispatch(getProject(project.id));
            }

            if ('type' in apiChangedFields) {
              /**
               * TODO: to move this to a GanttStore observer or reaction
               */
              cacheHelper.queryClient.refetchQueries(QUERY_CACHE_KEYS.taskTypes(project.id));
            }

            if (apiChangedFields.status) {
              cacheHelper.queryClient.refetchQueries([QUERY_CACHE_KEYS.feedback, taskId]);
            }

            tasksStore.updateTasks(tasks);

            const isBaselineEnabled = !!gantt.baselineCutoff;
            if (
              isBaselineEnabled &&
              (apiChangedFields.duration || apiChangedFields.startDate || apiChangedFields.endDate)
            ) {
              task.meta.variance = true;
              gantt.refreshTask(task.id);
              updateTaskVariance(gantt, task);
            }

            if (result.affected_tasks.length > 0) {
              if (isBaselineEnabled) {
                updateAffectedTasksVariance(gantt, task.projectId, affectedTaskIds);
              }
            }
          } catch (e) {
            task.lastChangedFields = {};
            Sentry.captureException(e);
          }

          return;
        });
      },
      create: (data: TaskDetailsModelDTO) => {
        if (typeof data.id === 'string') {
          return;
        }
        const tempId: number = data.id;
        const task = gantt.getTask(tempId);
        task.isPending = true;
        task.datesIsPristine = true;
        task.responsible = [
          {
            member_id: profile.id,
            member_name: profile.fullName,
          },
        ];

        const parent = task.parent;

        const preparedData = {
          ...task,
          parent,
          /**
           * NOTE: Kept getting a 400 error when trying to create a task with status: TaskStatus.assigned,
           * added this as a workaround prepareTaskForCreate remaps
           * taskStatus  -> status: task?.taskStatus as TaskStatus,
           */
          taskStatus: TaskStatusType.assigned,
          projectId: project.id,
          object_type: TaskObjectType.activity,
        };

        delete preparedData.id;

        const apiBody = prepareTaskForCreate(preparedData);

        // tempTask.end_date is set the render will not accurately display the task until after the api call resolves because gantt... 😢
        const tempTask = {
          ...toTaskModelRawDTO(apiBody),
          id: task.id,
          end_date: toShortIso(task.end_date),
          isPending: true,
          comment_count: 0,
          crews: [],
          zones: [],
        };

        const unfilteredIndex = getUnfilteredIndex(tasksStore.tasks, gantt, parent, task.$local_index);
        const changeSet = expandCreate(tasksStore.tasks, tempTask, parent, unfilteredIndex);

        // Make sure the new task gets introducted into tasksStore
        const tempTaskWithOSK = {
          ...tempTask,
          ...changeSet[tempId],
        };

        tasksStore.addTasks([tempTaskWithOSK]);

        // TODO: apply expansion of all tasks in changeSet in case create affects a WBS date
        applyChangeset(tasksStore, changeSet);

        updateFifoQueue.enqueue(async () => {
          try {
            // Raw, i.e. not camelCased.
            const responseData = await TasksApi.createTaskRaw(apiBody);
            responseData.isPending = tempTask.isPending;
            const postCreateId: string = responseData.id;
            // would be nice if this was a little more automatic.  Maybe in GanttStore
            // you can inspect oldValue and newValue and determine gantt.changeTaskId must be done
            updateFifoQueue.changeId(tempId, postCreateId);
            gantt.changeTaskId(tempId, responseData.id);
            tasksStore.changeTaskId(tempId, postCreateId);
            ganttStore.changeTaskId(tempId, postCreateId);

            const tasks: TaskModelRawDTO[] = [responseData];

            if (responseData.affected_task_ids?.length) {
              const affectedTasks = await TasksApi.getProjectTasks({
                params: {ids: responseData.affected_task_ids, projectId: project.id},
                projection: TaskProjection.task,
              });
              tasks.push(...(affectedTasks.data as TaskModelRawDTO[]));
            }
            responseData.isPending = false;
            tasksStore.updateTasks(tasks);
            return;
          } catch (error) {
            Sentry.captureException(error);

            const state = gantt.ext.inlineEditors.getState();
            gantt.ext.inlineEditors.hide();
            gantt.deleteTask(tempId);
            if (state.id) {
              gantt.ext.inlineEditors.startEdit(state.id, state.columnName);
            }
            toast.error(t('gantt:toast.error.action_create', `Activity creation failed, please try again.`));
          }
        });
      },
      delete: async (id: string) => {
        if (typeof id !== 'string') return;
        try {
          tasksStore.removeTasks([id]);

          await TasksApi.deleteTask(id);
          // We could fix this without an API call if we want.  It's intended to refresh
          // the root store project with the list of all values in use for task.type, task.location
          // and task.responsible_party to support lookahead controls.  We could trigger it
          // as an autorun from the TasksStore to keep the code out of data processor.
          dispatch(getProject(project.id));
          return {action: DataProcessorAction.Deleted};
        } catch (err) {
          tasksStore.loadTasks();
          return {action: DataProcessorAction.Error};
        }
      },
    },
    link: {
      update: (_data: GanttLinkModelDTO, _id: string) => {
        // not implemented
        console.warn('not implemented');
      },
      create: async (data: GanttLinkModelDTO) => {
        const job = async (tempIdMap: Map<number, string>) => {
          try {
            const permTargetId = typeof data.target === 'number' ? tempIdMap.get(data.target) : data.target;
            const permDepId = typeof data.source === 'number' ? tempIdMap.get(data.source) : data.source;
            // Lots of field duplication here.  Could be cleaned up.
            const link = await TasksApi.saveDependency(permTargetId, {
              delay: 0,
              delayUnit: 'days',
              depType: mapGanttLinkTypeToTaskDepType(gantt, data.type),
              depTaskId: permDepId,
              predTaskId: permDepId,
              lagDays: 0,
              taskId: permTargetId,
            });
            cacheHelper.prependItem(['tasksDependencies', project.id, gantt.name], link.dependency);
            return {tid: link.dependency.id, action: DataProcessorAction.Inserted};
          } catch (error) {
            return {action: DataProcessorAction.Error};
          }
        };

        updateFifoQueue.enqueue(job);

        return job;
      },
      delete: async (id: string) => {
        const job = async (tempIdMap: Map<number, string>) => {
          const link = linkToDelete?.current;

          const permTargetId = typeof link.target === 'number' ? tempIdMap.get(link.target) : link.target;

          if (!link || link.deletedAt) {
            return {action: DataProcessorAction.Deleted};
          } else {
            try {
              await TasksApi.deleteDependency(permTargetId, id);
              cacheHelper.removeItem(['tasksDependencies', project.id, gantt.name], id);
              return {action: DataProcessorAction.Deleted};
            } catch (error) {
              return {action: DataProcessorAction.Error};
            }
          }
        };

        updateFifoQueue.enqueue(job);

        return job;
      },
    },
  });

  processor.attachEvent('onBeforeUpdate', function (taskId: string, state: string) {
    if (state === 'order') {
      const moveRelative = async () => {
        try {
          await moveTask({
            taskId,
            gantt,
            projectId: project.id,
          });
        } catch (e) {
          return false;
        } finally {
          processor.setUpdated(taskId, false, 'order');
        }
      };

      simpleLock.runGanttLockOperation({
        callback: moveRelative,
        waitFor: 'load',
        showLoader: true,
      });

      return false;
    }
    return true;
  });

  return processor;
};
