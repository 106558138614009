import cn from 'classnames';
import dayjs from 'dayjs';
import Dinero from 'dinero.js';
import {TFunction} from 'react-i18next';

import s from 'shared/components/TasksImport/components/styles.module.scss';
import {formatInProjectTz} from 'shared/helpers/dates';

import {CurrencyObject, TaskImportField} from './types';

export enum TaskImportStep {
  SelectSource,
  Mapping,
  Additional,
  Confirm,
}

export enum TasksImportSourceType {
  file = 'file',
  proCore = 'proCore',
}

export const ProCoreErrors = {
  abortedAuthorization: 'The user has aborted authorization.',
};

export const statusSuffix = {
  created: 'new',
  updated: 'edit',
  failed: 'error',
  unchanged: 'unchanged',
};

export const stylesMapping = {
  uniqueId: 'id',
  name: 'name',
  schedStartDate: 'start',
  schedEndDate: 'due',
  subcontractor: 'sub',
  assignedNames: 'assigned',
  location: 'location',
  description: 'description',
  outlineCode: 'wbs',
  type: 'type',
  cost: 'cost',
};

export const getTableCellClass = (columnId: string) => {
  const mappedStyle = stylesMapping[columnId];
  return cn(
    'aka-table__cell',
    'aka-table__cell--td',
    // Base width for any unmapped/custom columns
    s['table__cell--default'],
    // Only add the mapped style if it exists
    mappedStyle && `aka-table__cell--${mappedStyle}`,
  );
};

export const getImportFields = (t: TFunction): TaskImportField[] => [
  {
    label: t('import:fields.id.label'),
    columnTitle: t('import:fields.id.title', 'ID'),
    key: 'uniqueId',
    required: true,
  },
  {
    label: t('import:fields.name.label'),
    columnTitle: t('import:fields.name.title'),
    key: 'name',
    required: true,
  },
  {
    label: t('import:fields.start.label'),
    columnTitle: t('import:fields.start.title'),
    key: 'schedStartDate',
    required: true,
    accessor: (value) => {
      if (typeof value === 'string') {
        // Parse the ISO timestamp, then convert to default (project) timezone and format
        return formatInProjectTz(dayjs(value), 'L');
      }
      return value;
    },
  },
  {
    label: t('import:fields.due.label', 'Due Date'),
    columnTitle: t('import:fields.due.title', 'Due'),
    key: 'schedEndDate',
    required: true,
    accessor: (value) => {
      if (typeof value === 'string') {
        return formatInProjectTz(dayjs(value), 'L');
      }
      return value;
    },
  },
  {
    label: t('import:fields.subcontractor.label', 'Subcontractor Group'),
    columnTitle: t('import:fields.subcontractor.title', 'Subcontractor Group'),
    key: 'subcontractor',
  },
  {
    label: t('import:fields.assignees.label', 'Assignees '),
    columnTitle: t('import:fields.assignees.title', 'Assigned to'),
    key: 'assignedNames',
    accessor: (value, data) => data.map((assignee) => assignee.name).join(' / '),
  },
  {
    label: t('import:fields.type.label', 'Type'),
    columnTitle: t('import:fields.type.title', 'Type'),
    key: 'type',
  },
  {
    label: t('import:fields.location.label', 'Location'),
    columnTitle: t('import:fields.location.title', 'Location'),
    key: 'location',
  },
  {
    label: t('import:fields.description.label', 'Description'),
    columnTitle: t('import:fields.description.title', 'Description'),
    key: 'description',
  },
  {
    label: t('import:fields.outline.label', 'Outline Number'),
    columnTitle: t('import:fields.outline.title', 'Outline Number'),
    key: 'outlineCode',
  },
  {
    label: t('import:fields.cost.label', 'Cost'),
    key: 'cost',
    columnTitle: t('import:fields.cost.title', 'Cost'),
    accessor: (value) => {
      return value?.cost
        ? Dinero({
            amount: (value.cost as CurrencyObject)?.amount ?? 0,
            currency: (value.cost as CurrencyObject)?.currency,
          }).toFormat()
        : t('import:fields.cost.not_applicable', 'N/A');
    },
  },
  {
    label: t('import:fields.predecessors.label', 'Predecessors'),
    columnTitle: t('import:fields.predecessors.title', 'Predecessors'),
    key: 'predecessors',
  },
  {
    label: t('import:fields.actualStart.label'),
    columnTitle: t('import:fields.actualStart.title'),
    key: 'actualStart',
    accessor: (value) => {
      if (typeof value === 'string') {
        return formatInProjectTz(dayjs(value), 'L');
      }
      return value;
    },
  },
  {
    label: t('import:fields.actualEnd.label'),
    columnTitle: t('import:fields.actualEnd.title'),
    key: 'actualEnd',
    accessor: (value) => {
      if (typeof value === 'string') {
        return formatInProjectTz(dayjs(value), 'L');
      }
      return value;
    },
  },
  {
    label: t('import:fields.targetAmount.label'),
    columnTitle: t('import:fields.targetAmount.title'),
    key: 'completionTarget',
  },
  {
    label: t('import:fields.targetUom.label'),
    columnTitle: t('import:fields.targetUom.title'),
    key: 'completionUnit',
  },
  {
    label: t('import:fields.estLabor.label'),
    columnTitle: t('import:fields.estLabor.title'),
    key: 'projectedLabor',
  },
  {
    label: t('import:fields.estLaborHours.label'),
    columnTitle: t('import:fields.estLaborHours.title'),
    key: 'estLaborHours',
  },
  {
    label: t('import:fields.costCode.label'),
    columnTitle: t('import:fields.costCode.title'),
    key: 'costCode',
  },
  {
    label: t('import:fields.customCode.label'),
    columnTitle: t('import:fields.customCode.title'),
    key: 'customCode',
  },
  {
    label: t('import:fields.csiCode.label'),
    columnTitle: t('import:fields.csiCode.title'),
    key: 'csiCode',
  },
  {
    label: t('import:fields.phaseCode.label'),
    columnTitle: t('import:fields.phaseCode.title'),
    key: 'phaseCode',
  },
  {
    label: t('import:fields.should.label', 'Should Import?'),
    key: 'shouldImport',
  },
];
