import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';

import ChatService from 'api/chat';
import FeedbackService from 'api/feedback';
import TasksApi from 'api/tasks';
import {CompletionUnits} from 'shared/constants/completionUnits';
import {toShortIso} from 'shared/helpers/dates';
import {
  FeedbackDailyTypeMap,
  Feedback,
  MessageTypeMap,
  MimeType,
  TagType,
  TagTypesMap,
  ContentMessageTypeMap,
} from 'shared/models/feedback';
import {MatrixSessionResponse} from 'shared/models/task/chat';
import {IssueModel} from 'shared/models/task/issue';
import {
  TaskCompletionUnits,
  TaskDetailsModelDTO,
  FeedbackProjectModelDTO,
  TaskWithAffectedResponse,
} from 'shared/models/task/task';

import {DailyCardUpdates} from '../components/DailyCard/DailyCard';
import {
  FeedbackImage,
  FeedbackMessage,
  MatrixValues,
  ProgressReportResponse,
  ProgressReportUpdates,
} from '../hooks/useHandleSubmitProgressReport';

import {extractFeedbackByDate, findFeedBackByDate, extractFeedbackHistory, getImageDimensions} from './helpers';
import {uploadImagesToMatrix} from './uploadImagesToMatrix';

export async function loadInitialData(id: string) {
  try {
    const task = await TasksApi.getTask(id);
    if (!task) {
      return null;
    }
    const avgLaborFeedbackByDate = extractFeedbackByDate(task.feedbackByDate, FeedbackDailyTypeMap.DailyLabor);
    const completionAmountFeedbackByDate = extractFeedbackByDate(
      task.feedbackByDate,
      FeedbackDailyTypeMap.DailyCompletion,
    );
    const completionUnit = (task.completionUnit as CompletionUnits) || CompletionUnits.Percent;
    const initialFeedback: DailyCardUpdates[] = task.dateList.map((date) => {
      const completionAmountFeedback = findFeedBackByDate(completionAmountFeedbackByDate, date);
      const avgLaborFeedback = findFeedBackByDate(avgLaborFeedbackByDate, date);
      const dailyLabor = avgLaborFeedback?.value ? parseInt(avgLaborFeedback.value.toString()) : null;
      const dailyCompletionAmount = completionAmountFeedback?.value
        ? parseInt(completionAmountFeedback.value.toString())
        : null;
      const dateTag = dayjs(date).startOf('day').toDate();
      return {
        completionUnit,
        dailyCompletionAmount,
        dailyLabor,
        dateTag,
        lastAvgLabor: extractFeedbackHistory(avgLaborFeedback) || undefined,
        lastProgress: extractFeedbackHistory(completionAmountFeedback) || undefined,
      };
    });
    const completionTarget = task.completionTarget || '100';
    return {
      initialTask: {
        ...task,
        completionUnit: (task.completionUnit as TaskCompletionUnits) || '%',
        completionTarget,
      },
      initialFeedback,
      lockedCompletionUnits: !!task.completionUnit,
    };
  } catch (err) {
    Sentry.captureException(err);
  }
}

export async function handleSubmitProgressReport(
  updates: ProgressReportUpdates,
  accessToken: MatrixSessionResponse['accessToken'],
) {
  const {feedback, initialFeedBack, task, image, comment} = updates;
  const hasComments = !!comment?.comment;
  let hasImages = false;
  const payload: Feedback[] = [];
  if (image?.images?.length) {
    hasImages = true;
  }
  const tags: TagType[] = comment?.flagAsPotentialIssue ? [TagTypesMap.PotentialIssues] : [];
  feedback?.forEach((day) => {
    const dayDate = dayjs(day.dateTag);
    const hasTags = tags.length > 0 && dayjs(comment.dateTag).startOf('day').isSame(dayDate);
    const {hasDailyLaborChanges, hasProgressChanges} = initialFeedBack.reduce(
      (acc, fbr) => {
        if (!acc.hasDailyLaborChanges || !acc.hasProgressChanges) {
          const fbrDate = dayjs(fbr.dateTag);
          const daysMatch = fbrDate.isSame(dayDate, 'day');
          return {
            hasDailyLaborChanges:
              acc.hasDailyLaborChanges || (daysMatch && fbr.dailyLabor !== day.dailyLabor) || hasTags,
            hasProgressChanges:
              acc.hasProgressChanges ||
              (daysMatch && fbr.dailyCompletionAmount !== day.dailyCompletionAmount) ||
              hasTags,
          };
        }
        return acc;
      },
      {hasDailyLaborChanges: false, hasProgressChanges: false},
    );

    if (hasDailyLaborChanges) {
      payload.push({
        date: toShortIso(day.dateTag),
        feedback_type: FeedbackDailyTypeMap.DailyLabor,
        payload: {value_integer: day.dailyLabor ?? null},
        tags,
      });
    }
    if (hasProgressChanges) {
      payload.push({
        date: toShortIso(day.dateTag),
        feedback_type: FeedbackDailyTypeMap.DailyCompletion,
        payload: {value_integer: day.dailyCompletionAmount ?? null},
        tags,
      });
    }
  });

  if (hasComments) {
    payload.push({
      feedback_type: comment.feedbackType,
      date: toShortIso(comment.dateTag),
      tags,
      payload: {
        [comment.feedbackType]: {
          content: {
            msgtype: ContentMessageTypeMap.text,
            body: comment.comment.trim(),
            format: 'org.matrix.custom.html',
            formatted_body: `<p>${comment.comment}</p>`,
          },
        },
      },
    });
  }

  if (hasImages) {
    const imageUploadPromises = image.images.map<Promise<Feedback>>(async (img) => {
      const {height, width} = await getImageDimensions(img);
      const mimetype = img.type as MimeType;
      const imageData = await ChatService.uploadAssetToMatrix(accessToken, img);
      return {
        feedback_type: image.feedbackType,
        date: toShortIso(image.dateTag),
        tags,
        payload: {
          [image.feedbackType]: {
            content: {
              body: img.name,
              info: {
                h: height,
                mimetype,
                size: img.size,
                w: width,
              },
              msgtype: ContentMessageTypeMap.image,
              url: imageData.contentUri,
            },
          },
        },
      };
    });
    const results = await Promise.all(imageUploadPromises);
    if (results.length) {
      payload.push(...results);
    }
  }

  const today = dayjs().startOf('day').toDate();
  const latestChanges = feedback
    .filter((fb) => fb.dateTag <= today && fb.dailyCompletionAmount != undefined)
    .sort((a, b) => dayjs(b.dateTag).valueOf() - dayjs(a.dateTag).valueOf());

  const latestCompletionAmount = latestChanges[0]?.dailyCompletionAmount;
  const taskPayload: Partial<TaskDetailsModelDTO> = {
    completionTarget: task.completionTarget,
    completionUnit: task.completionUnit,
    id: task.id,
    projectedLabor: task.projectedLabor,
  };

  const completionAmountPayload =
    latestCompletionAmount != undefined ? {completionAmount: latestCompletionAmount.toString()} : {};

  try {
    let feedbackResponse: FeedbackProjectModelDTO[];
    let taskUpdateResponse: TaskWithAffectedResponse;
    if (payload.length) {
      feedbackResponse = await FeedbackService.postFeedback(task.id, payload);
    }
    if (completionAmountPayload || taskPayload) {
      taskUpdateResponse = await TasksApi.updateTaskWithAffected({
        ...taskPayload,
        ...completionAmountPayload,
      });
    }
    const response: ProgressReportResponse = {comment_count: 0};
    if (feedbackResponse) {
      response.comment_count = Number(
        feedbackResponse.some(
          (fbr) => fbr.feedbackType === MessageTypeMap.message || fbr.feedbackType === MessageTypeMap.image,
        ),
      );
    }
    if (taskUpdateResponse) {
      response.taskUpdateResponse = taskUpdateResponse;
    }
    return response;
  } catch (err) {
    Sentry.captureException(err);
  }
}

export type CreateComment = {
  comment: Partial<FeedbackMessage>;
  image: FeedbackImage;
  flagAsPotentialIssue: boolean;
};

export async function handleSubmitComment({
  updates,
  accessToken,
  taskId,
  issueId,
  projectId,
}: {
  updates: CreateComment;
  accessToken: MatrixSessionResponse['accessToken'];
  taskId: string;
  issueId: string;
  projectId: string;
}) {
  const {image, comment, flagAsPotentialIssue} = updates;
  const hasComments = Boolean(comment?.comment);
  let hasImages = image?.images?.length > 0;
  const payload: Feedback[] = [];
  const tags: TagType[] = flagAsPotentialIssue ? [TagTypesMap.PotentialIssues] : [];

  // if we have image(s)
  if (image?.images?.length) {
    hasImages = true;
  }

  if (hasComments) {
    payload.push({
      feedback_type: comment.feedbackType,
      date: toShortIso(comment.dateTag),
      tags,
      payload: {
        [comment.feedbackType]: {
          content: {
            msgtype: 'm.text',
            body: comment.comment.trim(),
            format: 'org.matrix.custom.html',
            formatted_body: `<p>${comment.comment.trim()}</p>`, // safely trim the comment to prevent leading or trailing whitespaces
          },
        },
      },
    });
  }

  if (hasImages) {
    const results = await uploadImagesToMatrix(image, accessToken, comment.dateTag);
    if (results.length) {
      payload.push(...results);
    }
  }

  let feedbackResponse: FeedbackProjectModelDTO[] | IssueModel[];

  try {
    // send task-specific feedback
    feedbackResponse = issueId
      ? await FeedbackService.postIssueFeedback({projectId, issueId}, payload)
      : await FeedbackService.postFeedback(taskId, payload);

    const response = {comment_count: 0};

    if (feedbackResponse) {
      const taskCounts = new Set();

      feedbackResponse.forEach((feedback) => {
        if (feedback.feedbackType === MessageTypeMap.message || feedback.feedbackType === MessageTypeMap.image) {
          taskCounts.add(feedback.taskId);
        }
      });

      response.comment_count = taskCounts.size;
    }

    return response;
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        tag: 'handleSubmitComment',
      },
    });
  }
}

export async function getMatrixConnectionToken(values: MatrixValues) {
  const {workerId} = values;
  return ChatService.initializeMatrixSession(workerId);
}
