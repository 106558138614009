import cn from 'classnames';
import {FC} from 'react';

import {IssueModel} from 'shared/models/task/issue';

import {CommentsTimeline} from '../CommentsTimeline/CommentsTimeline';

import s from './ChatTimeline.module.scss';

type Props = {
  isLoading?: boolean;
  taskId: string;
  issue?: IssueModel;
  projectId?: string;
};

const ChatTimeline: FC<Props> = ({taskId, projectId, issue}) => (
  <div className={cn(s.ChatTimeline, 'loader-container')}>
    <CommentsTimeline issue={issue} taskId={taskId} projectId={projectId} />
  </div>
);

export default ChatTimeline;
