import cn from 'classnames';
import dayjs from 'dayjs';
import {MouseEvent, useCallback, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {ImageGallery} from 'modules/Tasks/components/SidebarPanel/IssuePanel/components/ImageGallery/ImageGallery';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import {Pill} from 'shared/components/Pill/Pill';
import ProgressBar from 'shared/components/ProgressBar';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';
import {HSpacer} from 'shared/components/Spacer/HSpacer';
import {VSpacer} from 'shared/components/Spacer/VSpacer';
import TaskStatus from 'shared/components/TaskStatus';
import TaskStatusIcon from 'shared/components/TaskStatusIcon';
import Tooltip from 'shared/components/Tooltip';
import {TasksViewMode} from 'shared/constants/common';
import env from 'shared/constants/env';
import {IconsMap} from 'shared/constants/icons';
import {getPrettyTaskStatuses} from 'shared/constants/statuses';
import {formatDate} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useAppConstants} from 'shared/hooks/useAppConstants';
import {useGetGroup} from 'shared/hooks/useGetGroup';
import {IOC_TYPES} from 'shared/models/ioc';
import {FeedbackGrouped, FeedbackModel} from 'shared/models/task/comments';
import {ExtChatEventMentionActivites, ExtChatEventMentionIssues, Attachment} from 'shared/models/task/task';
import {useInjectStore} from 'shared/providers/injection';
import {TasksStoreType} from 'shared/stores/TasksStore';

import s from './styles.module.scss';
import {formatTaskHierarchy, getUniqueTags, sanitizedHTML} from './utils';

function CommentTypeHeader({
  iconName = null,
  heading = null,
  submitterName = null,
  timeCreated = null,
  date,
  taskId,
}: {
  iconName?: string;
  heading?: string;
  submitterName?: string;
  timeCreated?: string;
  date?: string;
  taskId?: string;
}) {
  const history = useHistory();
  const tasksStore = useInjectStore<TasksStoreType>(IOC_TYPES.TasksStore);
  const task = tasksStore.getTaskById(taskId);
  const {updateSearchParams} = useFilterContext();
  const formattedTimeCreated = timeCreated ? dayjs.utc(timeCreated).local().format('h:mm A') : null;
  const schedEndFirst = dayjs(date).toDate();

  const shouldShowIcon = dayjs(date).isBetween(task?.start_date, task?.end_date, null, '[]');

  const navigateToDailies = useCallback(() => {
    history.replace({
      state: {taskId},
    });
    updateSearchParams({view: TasksViewMode.dailies, schedEndFirst});
  }, [history, schedEndFirst, taskId, updateSearchParams]);

  return (
    <div className={s['comment-card-header']}>
      <div className={s['comment-card-header--heading']}>
        <Icon name={iconName} />
        <p>{heading}</p>
      </div>
      <div>
        <div className={s['comment-card-header--reporter']}>
          {formattedTimeCreated ? (
            <p>
              <Trans
                i18nKey="comments:comments_list.comments.comment_type_header.submitter"
                components={{
                  span: <span className={s['comment-card-header--submitter']} />,
                }}
                values={{formattedTimeCreated, submitterName}}
              />
              {shouldShowIcon ? <Icon name={IconsMap['new-tab']} onClick={navigateToDailies} /> : null}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function CommentTags({tags}) {
  const {t} = useTranslation('comments');
  const tagTypes = {
    potential_issues: {
      color: 'red',
      displayText: t('comments_list.comments.comment_tags.potential_issue'),
    },
  };

  if (!tags.length) {
    return null;
  }

  return (
    <>
      <VSpacer size="2" />

      <CommentTypeHeader
        heading={t('comments_list.comments.comment_type_header.heading.flags')}
        iconName={IconsMap.flag}
      />

      <VSpacer size="4" />

      {tags?.map((tag: string) => {
        const tagInfo = tagTypes[tag] || {}; // fallback to an empty object if no defined style
        return (
          <Pill size="xs" icon={<Icon name={IconsMap.flag} size={14} />} key={tag} color={tagInfo.color}>
            {tagInfo.displayText}
          </Pill>
        );
      })}
    </>
  );
}

/**
 * NOTE: currently not in used as we do not have the ability to pull the
 * historical data for this. Re-enable when data is available
 */
// export function CommentCardSubHeading({subHeading}: {subHeading: string}) {
//   return (
//     <div className={s['comment-card-report']}>
//       <div className={s['comment-card-report--heading']}>
//         <p>{subHeading}</p>
//         <p>{'2 → 3'}</p>
//       </div>
//     </div>
//   );
// }

function CommentCardReport({
  date,
  valueInteger,
  completionTargetAmount = '',
  taskCompletionUnit = '',
  projectedLabor = '',
}: {
  date: string;
  valueInteger: number;
  completionTargetAmount?: string;
  taskCompletionUnit?: string;
  projectedLabor?: string;
}) {
  const formatedDate = dayjs(date).format('MMMM D, YYYY');
  let formattedOutput = `${valueInteger}`;

  if (completionTargetAmount !== '') {
    formattedOutput += ` → ${completionTargetAmount}${taskCompletionUnit}`;
  } else {
    if (projectedLabor === '') {
      formattedOutput += `${projectedLabor}`;
    } else {
      formattedOutput += ` → ${projectedLabor}`;
    }
  }

  return (
    <div className={s['comment-card-report']}>
      <div className={s['comment-card-report--report']}>
        <p>{formatedDate}</p>
        <p>{formattedOutput}</p>
      </div>
    </div>
  );
}

function DailyLaborComment({
  dailyLaborComments,
  date,
  taskId,
}: {
  dailyLaborComments: FeedbackModel[];
  date: string;
  taskId: string;
}) {
  const {t} = useTranslation('comments');
  const tasksStore = useInjectStore<TasksStoreType>(IOC_TYPES.TasksStore);
  const task = tasksStore.getTaskById(taskId);
  const projectedLabor = task?.projected_labor;
  const hasDailyLabor = dailyLaborComments?.length > 0;
  const submitterName = dailyLaborComments?.[0]?.workerFullName;
  const timeCreated = dailyLaborComments?.[0]?.timeCreated;

  if (!hasDailyLabor) {
    return null;
  }

  return (
    <>
      <CommentTypeHeader
        date={date}
        heading={t('comments_list.comments.comment_type_header.heading.man_power_updated')}
        iconName={IconsMap.user_hardhat}
        submitterName={submitterName}
        taskId={taskId}
        timeCreated={timeCreated}
      />

      {/* <CommentCardSubHeading */}
      {/*   subHeading={t('comments_list.comments.comment_type_header.sub_heading.crew_size_updated')} */}
      {/* /> */}

      {dailyLaborComments?.map((dailyLaborComment) => (
        <CommentCardReport
          key={dailyLaborComment.id}
          date={dailyLaborComment.date}
          valueInteger={dailyLaborComment.valueInteger}
          projectedLabor={projectedLabor}
        />
      ))}

      <CommentCardSeparator />
    </>
  );
}

function CompletionAmountComment({
  completionAmountComments,
  date,
  taskId,
}: {
  completionAmountComments: FeedbackModel[];
  date: string;
  taskId: string;
}) {
  const {t} = useTranslation('comments');
  const tasksStore = useInjectStore<TasksStoreType>(IOC_TYPES.TasksStore);
  const task = tasksStore.getTaskById(taskId);
  const taskCompletionTarget = task?.completion_target;
  const taskCompletionUnit = task?.completion_unit;
  const hasCompletionAmount = completionAmountComments?.length > 0;
  const submitterName = completionAmountComments?.[0]?.workerFullName;
  const timeCreated = completionAmountComments?.[0]?.timeCreated;
  // we only care about latest completion amount grab last in the list as they are sorted by date
  const lastComment = completionAmountComments?.[completionAmountComments?.length - 1];
  const completionAmountValue = (Number(lastComment?.valueInteger) / Number(taskCompletionTarget)) * 100;

  if (!hasCompletionAmount) {
    return null;
  }

  return (
    <>
      <CommentTypeHeader
        date={date}
        heading={t('comments_list.comments.comment_type_header.heading.progress_updated')}
        iconName={IconsMap.technology}
        submitterName={submitterName}
        taskId={taskId}
        timeCreated={timeCreated}
      />

      {/* <CommentCardSubHeading subHeading={t('comments_list.comments.comment_type_header.sub_heading.total_amount_updated')} /> */}

      <div className={s['comment-card__progress-bar']}>
        <ProgressBar progress={completionAmountValue} />
      </div>

      {completionAmountComments?.map((completionComment) => (
        <CommentCardReport
          key={completionComment.id}
          date={completionComment.date}
          valueInteger={completionComment.valueInteger}
          completionTargetAmount={taskCompletionTarget}
          taskCompletionUnit={taskCompletionUnit}
        />
      ))}

      <CommentCardSeparator />
    </>
  );
}

function MessageComment({
  messageComments,
  date,
  taskId,
}: {
  messageComments: FeedbackModel[];
  date: string;
  taskId: string;
}) {
  const {t} = useTranslation('comments');
  const hasMessages = messageComments?.length > 0;
  const submitterName = messageComments?.[0]?.workerFullName;
  const timeCreated = messageComments?.[0]?.timeCreated;

  if (!hasMessages) {
    return null;
  }

  return (
    <>
      <CommentTypeHeader
        date={date}
        heading={t('comments_list.comments.comment_type_header.heading.comment')}
        iconName={IconsMap['comment-vs']}
        submitterName={submitterName}
        taskId={taskId}
        timeCreated={timeCreated}
      />

      <VSpacer size="2" />

      {messageComments?.map((messageComment) => (
        <div key={messageComment.id} className={s['comment-card-report']}>
          <div className={s['comment-card-report--report']}>{messageComment.body}</div>
        </div>
      ))}

      <CommentCardSeparator />

      <VSpacer size="2" />
    </>
  );
}

function ImageComment({imageComments, date, taskId}: {imageComments: FeedbackModel[]; date: string; taskId: string}) {
  const {t} = useTranslation('comments');
  const hasImages = imageComments?.length > 0;
  const submitterName = imageComments?.[0]?.workerFullName;
  const timeCreated = imageComments?.[0]?.timeCreated;
  const attachments = imageComments?.map((comment) => ({
    url: comment.imageUrl,
  }));

  if (!hasImages) {
    return null;
  }

  return (
    <>
      <CommentTypeHeader
        date={date}
        heading={t('comments_list.comments.comment_type_header.heading.attachments')}
        iconName={IconsMap.image}
        submitterName={submitterName}
        taskId={taskId}
        timeCreated={timeCreated}
      />

      <VSpacer size="2" />

      <ImageGallery attachments={attachments} />

      <VSpacer size="2" />

      <CommentCardSeparator forceDisplay />
    </>
  );
}

function TaskEventComments({taskEventComments}: {taskEventComments: FeedbackModel[]}) {
  const {t} = useTranslation('comments');
  return (
    <>
      {taskEventComments?.map((task) => (
        <div className={s['task-row']} key={task.id}>
          <span>{t('comments_list.comments.task_status', {name: task.workerFullName})}</span>
          <HSpacer />
          <TaskStatus value={task.status} />
          <HSpacer />
          <span>{t('comments_list.comments.task_status_time', {time: dayjs(task.timeCreated).format('h:mm A')})}</span>
        </div>
      ))}
    </>
  );
}

function ExtChatEventActivityItem({
  activity: data,
}: {
  activity: ExtChatEventMentionActivites | ExtChatEventMentionIssues;
}) {
  const {t} = useTranslation('common');

  const dataIsIssueMention = (
    data: ExtChatEventMentionActivites | ExtChatEventMentionIssues,
  ): data is ExtChatEventMentionIssues => {
    return 'issueId' in data;
  };

  const className = cn(s['mention-container__inner-card-content'], {
    [s['mention-container__inner-card-content-activity']]: !dataIsIssueMention(data),
  });

  const renderContent = () => {
    if (dataIsIssueMention(data)) {
      return <span className={className}>{data.name}</span>;
    } else {
      return <span className={className}>{formatTaskHierarchy(data)}</span>;
    }
  };

  return (
    <li
      className={s['mention-container__inner-card-item']}
      data-bycore-task-id={!dataIsIssueMention(data) ? data.taskId : data.issueId}
      data-bycore-is-issue={dataIsIssueMention(data)}
      data-bycore-status={data.status}
    >
      <Tooltip text={getPrettyTaskStatuses(t)[data.status]}>
        <Button
          iconOnly
          icon={
            <TaskStatusIcon className={s['mention-container__inner-card-task-icon']} name={data.status} size={15} />
          }
        />
      </Tooltip>
      {renderContent()}
      <div>
        <Icon className={s['mention-container__inner-card-arrow-icon']} name={IconsMap.arrow_2_right} size={20} />
      </div>
    </li>
  );
}

function ExtChatGroupName(comment: FeedbackModel) {
  const {data: group, isLoading} = useGetGroup(comment.groupId);

  return (
    <SkeletonPreloader when={isLoading} className={s['mention-container__title-container--loading']}>
      <div className={s['mention-container__title-container']}>
        <div className={s['mention-container__icon-container']}>
          <Icon className={s['mention-container__icon']} name={IconsMap.clipboard} />
        </div>
        <div className={s['mention-container__title']}>{group?.name}</div>
      </div>
    </SkeletonPreloader>
  );
}

function ExtChatWorkerInfo(comment: FeedbackModel) {
  const {t} = useTranslation('comments');
  return (
    <div className={s['mention-container__inner__worker-info']}>
      <Trans
        i18nKey="comments_list.ext_chat_event.info"
        values={{
          by: t('comments_list.ext_chat_event.by'),
          date: formatDate(comment.timeCreated, 'h:mm a'),
          name: comment.workerFullName,
        }}
      >
        {'{{date}} {{by}}'}
        <span className={s['mention-container__inner__worker-info--highlighted']}>{' {{name}}'}</span>
      </Trans>
    </div>
  );
}

function ExtChatEventComment({extChatEventComments}: {extChatEventComments: FeedbackModel[]}) {
  const ref = useRef<HTMLDivElement>();
  const [expanded, setExpanded] = useState(false);
  const appConstants = useAppConstants();
  const homeserver = appConstants?.locales?.uS.matrix.homeserver;
  const {
    mixpanel: {
      events: {tasks},
      ...mixpanel
    },
  } = useAnalyticsService();

  const onClickTag = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const handleClick = () => {
        e.preventDefault();
        const roomId = e.currentTarget.getAttribute('data-room-id');
        const eventId = e.currentTarget.getAttribute('data-event-id');
        if (roomId) {
          window.open(`${env.webChatClient}/home/${encodeURIComponent(roomId)}/${eventId}`, '_blank');
        }
      };

      // Get the actual clicked element (the span)
      const clickedElement = e.target as HTMLElement;
      // Check if it's a issue or activity mention by looking at the text content
      if (clickedElement.textContent?.startsWith('#') || clickedElement.textContent?.startsWith('^')) {
        const isIssueMention = clickedElement.textContent.startsWith('^');

        const eventName = isIssueMention
          ? tasks.sidePanel.comments.issueMention
          : tasks.sidePanel.comments.activtiyMention;

        return mixpanel.trackWithAction(handleClick, eventName, {
          roomId: e.currentTarget.getAttribute('data-room-id'),
          eventId: e.currentTarget.getAttribute('data-event-id'),
        });
      }

      // If not a mention, just execute the click handler
      return handleClick();
    },
    [mixpanel, tasks.sidePanel.comments.activtiyMention, tasks.sidePanel.comments.issueMention],
  );

  const comments = extChatEventComments?.map((comment) => {
    const seen = new Set();
    const activities =
      comment?.activities?.filter((act) => {
        const duplicate = seen.has(act.taskId);
        seen.add(act.taskId);
        return !duplicate;
      }) ?? [];
    const issues =
      comment?.issues?.filter((act) => {
        const duplicate = seen.has(act.issueId);
        seen.add(act.issueId);
        return !duplicate;
      }) ?? [];
    return {
      ...comment,
      activities,
      issues,
    };
  });

  return (
    <>
      {comments?.map((comment) => {
        const attachments: Attachment[] =
          comment.attachments?.map((attachment) => {
            if (attachment?.url.startsWith('mxc://')) {
              const imageId = attachment.url.slice(6);
              const url = `${homeserver}/_matrix/media/v3/download/${imageId}`;
              return {
                ...attachment,
                url,
              };
            }
            return attachment;
          }) ?? [];

        const body = comment.body?.replace(/<mx-reply>.*?<\/mx-reply>/s, '');

        return (
          <div key={comment.id}>
            <ExtChatGroupName {...comment} />
            <div className={s['mention-container__outer']} data-matrix-event-id={comment.eventId}>
              <div className={s['mention-container__inner']}>
                <ExtChatWorkerInfo {...comment} />
                <div
                  className={cn(s['mention-container__inner-gallery'], {
                    [s['mention-container__inner-gallery--expanded']]: expanded,
                  })}
                >
                  <ImageGallery handleExpanded={setExpanded} attachments={attachments} isMatrixImage />
                  <div
                    className={s['mention-container__inner-gallery-content']}
                    dangerouslySetInnerHTML={{__html: sanitizedHTML(body)}}
                    data-room-id={comment.roomId}
                    data-event-id={comment.eventId}
                    onClick={onClickTag}
                    ref={ref}
                  />
                </div>
                <ul className={cn(s['mention-container__inner-card-container'], 'extChatEventActivityItemList')}>
                  {comment?.activities?.map((activity) => (
                    <ExtChatEventActivityItem activity={activity} key={activity.taskId} />
                  ))}
                  {comment?.issues?.map((issues) => (
                    <ExtChatEventActivityItem activity={issues} key={issues.issueId} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export function CommentCardBody({commentContent, taskId}: {commentContent: FeedbackGrouped; taskId: string}) {
  const feedbackTypes = commentContent?.feedbackTypes;
  const completionAmountComments = feedbackTypes?.completionAmountComments;
  const dailyLaborComments = feedbackTypes?.dailyLaborComments;
  const messageComments = feedbackTypes?.mMessageComments;
  const imageComments = feedbackTypes?.mImageComments;
  const taskEventComments = feedbackTypes?.taskEventComments;
  const extChatEventComments = feedbackTypes.extChatEventComments;
  const uniqueTags = getUniqueTags([completionAmountComments, dailyLaborComments, messageComments, imageComments]);

  return (
    <>
      <ExtChatEventComment extChatEventComments={extChatEventComments} />
      <TaskEventComments taskEventComments={taskEventComments} />
      <DailyLaborComment date={commentContent.date} dailyLaborComments={dailyLaborComments} taskId={taskId} />
      <CompletionAmountComment
        date={commentContent.date}
        completionAmountComments={completionAmountComments}
        taskId={taskId}
      />
      <MessageComment date={commentContent.date} messageComments={messageComments} taskId={taskId} />
      <ImageComment date={commentContent.date} imageComments={imageComments} taskId={taskId} />
      <CommentTags tags={uniqueTags} />
    </>
  );
}

export function CommentCardSeparator({forceDisplay = false}: {forceDisplay?: boolean}) {
  const className = cn({
    [s['hr-divider']]: !forceDisplay,
    [s.forceDisplay]: forceDisplay,
  });
  return <hr className={className} />;
}

export function CommentCard({children, day}: {children: React.ReactNode; day: string}) {
  return (
    <div className={s['comment-card']} id={day}>
      {children}
    </div>
  );
}
