import {InitExportResponse, TaskExportPayload, TaskExportResponse} from 'shared/components/TasksExport/types';
import {prepareTaskFilters} from 'shared/helpers/tasks';

import ApiAxios from './axios';
import {sleep} from './utils/sleep';

class ExportService {
  private static instance: ExportService | null = null;

  public static getInstance(): ExportService {
    if (!ExportService.instance) {
      ExportService.instance = new ExportService();
    }
    return ExportService.instance;
  }

  private getBasePath(companyId: string) {
    return `/companies/${companyId}/tasks/export`;
  }

  exportTasks(companyId: string, payload: Partial<TaskExportPayload>) {
    return ApiAxios.post<InitExportResponse>(this.getBasePath(companyId), null, {
      params: {
        filter_params: JSON.stringify(prepareTaskFilters(payload.params)),
        dateformat: payload.dateFormat,
      },
    });
  }

  exportTasksStatus(companyId: string, importId: string) {
    return ApiAxios.get<TaskExportResponse>(`${this.getBasePath(companyId)}/${importId}/status`);
  }

  async pollExportTasksResults(
    companyId: string,
    importId: string,
    maxWaitSec: number,
    onProgress?: (data: TaskExportResponse) => void,
  ): Promise<TaskExportResponse | null> {
    const startTime = Date.now();

    while (true) {
      const resp = await this.exportTasksStatus(companyId, importId);
      const data = resp.data;

      if (!data) {
        throw new Error('No data returned from exportTasksStatus API.');
      }

      if (onProgress) {
        onProgress(data);
      }

      if (data.result?.error === 'Unexpected server error') {
        throw new Error('Unexpected server error during polling.');
      }

      if (data.status !== 'queued' && data.status !== 'running') {
        return data;
      }

      const elapsedTime = (Date.now() - startTime) / 1000;
      if (elapsedTime >= maxWaitSec) {
        throw new Error('Polling exceeded the maximum wait time.');
      }

      await sleep(2000);
    }
  }
}

export default ExportService.getInstance();
