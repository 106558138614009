import {FC, memo, useEffect} from 'react';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {IssueModel} from 'shared/models/task/issue';
import {TaskDetailsModelDTO} from 'shared/models/task/task';

import ChatTimeline from './ChatTimeline';

type Props = {
  task?: TaskDetailsModelDTO;
  projectId?: string;
  issue?: IssueModel;
};

const CommentsTab: FC<Props> = ({task, projectId, issue}) => {
  const {viewMode} = useFilterContext();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.task;

  useEffect(() => {
    if (task?.id) {
      mixpanel.track(mixpanelEvents.actions.commentsTabLand, {taskType: task.objectType});
    }
  }, [mixpanel, mixpanelEvents.actions.commentsTabLand, task?.id, task?.objectType]);

  return <ChatTimeline taskId={task?.id} issue={issue} projectId={projectId} />;
};

export default memo(CommentsTab);
