import {GanttStatic} from 'dhtmlx-gantt';
import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ConfirmationPopup from 'shared/components/Confirmation/ConfirmationPopup';
import Popup from 'shared/components/Popup/Popup';

import ProgressReport, {ProgressReportRef} from '../ProgressReport/ProgressReport';

import styles from './progressReportPopup.module.scss';

interface ProgressReportingPopupProps {
  gantt?: GanttStatic;
  onClose: () => void;
  taskId: string;
}

export const ProgressReportingPopup = ({gantt, onClose, taskId}: ProgressReportingPopupProps) => {
  const {t} = useTranslation('dailies');
  const progressReportRef = useRef<ProgressReportRef>(null);
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);

  const handleCloseProgressReportPopup = () => {
    if (progressReportRef.current?.hasChanges) {
      setOpenUnsavedChangesDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirmDialog = () => {
    setOpenUnsavedChangesDialog(false);
    onClose();
  };

  return (
    <>
      <Popup
        className={styles.popup}
        closeOnOutsideClick={false}
        isCloseButtonInside
        onClose={handleCloseProgressReportPopup}
        visible={!!taskId}
      >
        <Popup.Body style={{scrollbarWidth: 'none'}}>
          <ProgressReport gantt={gantt} onClose={onClose} ref={progressReportRef} taskId={taskId} />
        </Popup.Body>
      </Popup>
      <ConfirmationPopup
        acceptButton={t('progress_report.confirm_submit_without_saving.yes')}
        cancelButton={t('progress_report.confirm_submit_without_saving.cancel')}
        description={t('progress_report.confirm_submit_without_saving.body')}
        iconName="warning"
        onAccept={handleConfirmDialog}
        onClose={() => setOpenUnsavedChangesDialog(false)}
        onReject={() => setOpenUnsavedChangesDialog(false)}
        title={t('progress_report.confirm_submit_without_saving.title')}
        visible={openUnsavedChangesDialog}
      />
    </>
  );
};
