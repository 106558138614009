import dayjs from 'dayjs';
import {useState} from 'react';
import {TFunction} from 'react-i18next';

import s from 'modules/Tasks/DailyRisk/DailyRisk.module.scss';
import {classify, getFlagSvgAttributes, TaskStatusFlag} from 'modules/Tasks/DailyRisk/TaskFlagClassifier';
import {OSKMap, SubMap, SectionRow} from 'modules/Tasks/DailyRisk/types';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import Icon from 'shared/components/Icon';
import TaskStatusIcon from 'shared/components/TaskStatusIcon';
import {TaskLabelCategory, TaskLabelCategoryMap} from 'shared/constants/dailyStatus';
import {getTaskIssueImpactOptions} from 'shared/constants/statuses';
import {TaskObjectType} from 'shared/models/task/const';
import {TaskGanttModel, TaskDetailsModelDTO, TaskIssueImpact} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {WeatherForecastDay} from 'shared/models/weather';

export function getWBSText(osk: string, oskMap: {[osk: string]: TaskGanttModel | TaskDetailsModelDTO}): string {
  if (!osk || !oskMap) {
    return '';
  }

  const oskElems = osk.split('.');
  const names = [];

  for (let ctr = 0; ctr < oskElems.length - 1; ctr++) {
    const key = oskElems.slice(0, ctr + 1).join('.');
    const wbsParent = oskMap[key];

    if (wbsParent && wbsParent.name) {
      names.push(wbsParent.name);
    }
  }

  return names.join(' → ');
}

export const populateOSKMap = (items: TaskGanttModel[], oskMap: {[osk: string]: TaskGanttModel}): TaskGanttModel[] => {
  for (const item of items) {
    if (item.object_type === TaskObjectType.summary) {
      oskMap[item.outline_sort_key] = item;
    }
  }
  return items.filter((item) => item.object_type !== TaskObjectType.summary);
};

/**
 * t('gantt:risk.labels.electrocution', 'Electrocution')
 * t('gantt:risk.labels.extreme_heat', 'Extreme Heat')
 * t('gantt:risk.labels.fall_hazard', 'Fall Hazard')
 * t('gantt:risk.labels.fire', 'Fire');
 * t('gantt:risk.labels.respiratory_hazard', 'Respiratory Hazard');
 * t('gantt:risk.labels.soil_failure', 'Soil Failure');
 * t('gantt:risk.labels.equipment_failure', 'Equipment Failure');
 * t('gantt:risk.labels.egress', 'Safe Egress');
 * t('gantt:risk.labels.existing_utilities', 'Existing Utilities');
 * t('gantt:risk.labels.struck_by', 'Struck By');
 * t('gantt:risk.labels.fit_and_finish', 'Fit and Finish');
 * t('gantt:risk.labels.acceptable_substrate', 'Acceptable Substrate');
 * t('gantt:risk.labels.verify_in_field', 'Verify in Field');
 * t('gantt:risk.labels.specified_material', 'Specified Material');
 * t('gantt:risk.labels.tolerances', 'Tolerances');
 * t('gantt:risk.labels.logistics', 'Logistics');
 * t('gantt:risk.labels.long_lead', 'Long Lead');
 * t('gantt:risk.labels.wind', 'High Winds Forecast');
 * t('gantt:risk.labels.rain', 'Rain Forecast');
 */

// OSHA limit above which material handling should be suspended
const WIND_AFFECT_LIMIT = 30;
// OSHA limit above which wind poses a general risk
const WIND_SAFETY_LIMIT = 40;
// Somewhat arbitrarily chosen limit above which we'll warn about rainfall
const RAIN_CHANCE_LIMIT = 20;

export const getSafety = (
  tasks: TaskGanttModel[],
  forecast: WeatherForecastDay,
  labelCategories: TaskLabelCategoryMap,
  day: Date,
  oskMap: OSKMap,
  subMap: SubMap,
  t: TFunction,
) => {
  if (!tasks) {
    return [];
  }

  const dayStr = day.toISOString().split('T')[0];
  const maxWind = forecast?.day?.maxwind_mph || 0;
  const maxTemp = forecast?.day?.maxtemp_f || 0;

  const rows: SectionRow[] = [];

  if (maxTemp > 80) {
    rows.push({
      taskName: t('risk.activities.outdoor_work', 'All outdoor work'),
      wbs: null,
      notes: [t('risk.labels.extreme_heat', 'Extreme Heat')],
      icons: [{iconName: 'sun', color: 'black', title: t('risk.labels.extreme_heat', 'Extreme Heat')}],
    } as SectionRow);
  }

  for (const task of tasks) {
    if (task.object_type === 'summary' || task.date_list.indexOf(dayStr) < 0) {
      continue;
    }

    if (task.risk) {
      const wbsText = getWBSText(task.outline_sort_key, oskMap);
      const flags = classify(forecast, labelCategories, task, dayStr, TaskLabelCategory.safety);
      const row: SectionRow = {
        taskName: task.name,
        wbs: wbsText,
        sub: subMap[task.responsible_org_id] || '',
        notes: [],
        icons: flags.map(getFlagSvgAttributes),
      };

      const labels = task.risk?.labels || [];
      for (const entry of labels) {
        if (entry.label === 'wind' && maxWind >= WIND_AFFECT_LIMIT) {
          row.notes.push(t(`risk.labels.${entry.label}`));
        } else if (entry.label === 'extreme_heat') {
          continue;
        } else if (labelCategories[entry.label] === TaskLabelCategory.safety) {
          row.notes.push(t(`risk.labels.${entry.label}`));
        }
      }

      if (row.notes.length) {
        rows.push(row);
      }
    }
  }

  return rows;
};

function startsOnDay(task: TaskGanttModel, dayStr: string): boolean {
  return task.date_list[0] === dayStr;
}

function endsOnDay(task: TaskGanttModel, dayStr: string): boolean {
  return task.date_list[task.date_list.length - 1] === dayStr;
}

export const getPlanning = (
  tasks: TaskGanttModel[],
  issues: TaskGanttModel[],
  forecast: WeatherForecastDay,
  labelCategories: TaskLabelCategoryMap,
  day: Date,
  oskMap: OSKMap,
  subMap: SubMap,
  t: TFunction,
) => {
  if (!tasks) {
    return [];
  }

  const dayStr = day.toISOString().split('T')[0];

  const rows: SectionRow[] = [];

  const impactOptions = getTaskIssueImpactOptions(t);
  for (const issue of issues) {
    if (issue.impact !== TaskIssueImpact.None) {
      // decode the impact text
      const impactLabel = impactOptions.find((opt) => opt.value === issue.impact).label;
      const row: SectionRow = {
        taskName: issue.name,
        wbs: getWBSText(issue.outline_sort_key, oskMap),
        notes: [impactLabel],
        sub: subMap[issue.responsible_org_id] || '',
        icons: [TaskStatusFlag.planning].map(getFlagSvgAttributes),
      };
      rows.push(row);
    }
  }

  for (const task of tasks) {
    if (task.object_type === 'summary' || task.date_list.indexOf(dayStr) < 0) {
      continue;
    }

    if (task.risk) {
      const flags = classify(forecast, labelCategories, task, dayStr, TaskLabelCategory.planning);
      const row: SectionRow = {
        taskName: task.name,
        wbs: getWBSText(task.outline_sort_key, oskMap),
        notes: [],
        sub: subMap[task.responsible_org_id] || '',
        icons: flags.map(getFlagSvgAttributes),
      };

      const labels = task.risk.labels || [];
      for (const entry of labels) {
        if (labelCategories[entry.label] === TaskLabelCategory.planning) {
          row.notes.push(t(`risk.labels.${entry.label}`));
        }
      }

      if (startsOnDay(task, dayStr)) {
        row.notes.push(t('risk.labels.starts_today', 'Starts today'));
      } else if (endsOnDay(task, dayStr)) {
        row.notes.push(t('risk.labels.ends_today', 'Ends today'));
      }

      if (row.notes.length) {
        rows.push(row);
      }
    }
  }

  return rows;
};

export const getQuality = (
  tasks: TaskGanttModel[],
  forecast: WeatherForecastDay,
  labelCategories: TaskLabelCategoryMap,
  day: Date,
  oskMap: OSKMap,
  subMap: SubMap,
  t: TFunction,
) => {
  if (!tasks) {
    return [];
  }

  const dayStr = day.toISOString().split('T')[0];

  const rows: SectionRow[] = [];
  for (const task of tasks) {
    if (task.object_type === 'summary' || task.date_list.indexOf(dayStr) < 0) {
      continue;
    }

    if (task.risk) {
      const flags = classify(forecast, labelCategories, task, dayStr, TaskLabelCategory.quality);
      const row: SectionRow = {
        taskName: task.name,
        wbs: getWBSText(task.outline_sort_key, oskMap),
        notes: [],
        sub: subMap[task.responsible_org_id] || '',
        icons: flags.map(getFlagSvgAttributes),
      };

      const labels = task.risk.labels || [];
      for (const entry of labels) {
        if (labelCategories[entry.label] === TaskLabelCategory.quality) {
          row.notes.push(t(`risk.labels.${entry.label}`));
        }
      }

      if (row.notes.length) {
        rows.push(row);
      }
    }
  }

  return rows;
};

export const getExternalFactors = (tasks: TaskGanttModel[], day: Date, forecast: WeatherForecastDay, t: TFunction) => {
  if (!tasks) {
    return [];
  }

  const maxWind = forecast?.day.maxwind_mph || 0;
  const rainChance = forecast?.day.daily_chance_of_rain || 0;

  const rows: SectionRow[] = [];
  const weatherRow: SectionRow = {
    taskName: t('risk.activities.weather_affected', 'Activities affected by weather delays'),
    wbs: null,
    notes: [],
    icons: [],
  };

  if (maxWind > WIND_SAFETY_LIMIT) {
    weatherRow.notes.push(t('risk.labels.wind'));
    weatherRow.icons.push({iconName: 'wind', color: 'black', title: t('risk.labels.wind', 'High Winds Forecast')});
  }
  if (rainChance > RAIN_CHANCE_LIMIT) {
    weatherRow.notes.push(t('risk.labels.rain'));
    weatherRow.icons.push({iconName: 'rain', color: 'black', title: t('risk.labels.rain', 'Rain Forecast')});
  }
  if (weatherRow.notes.length) {
    rows.push(weatherRow);
  }

  return rows;
};

export const getBlocked = (tasks: TaskGanttModel[], oskMap: OSKMap, subMap: SubMap, t: TFunction) => {
  if (!tasks) {
    return [];
  }

  const rows = tasks.map((task) => {
    const notStarted = dayjs().isBefore(dayjs(task.start_date));
    const overdue = dayjs().isAfter(dayjs(task.end_date));
    const startDate = dayjs(task.date_list[0]).format('L');
    const dueDate = dayjs(task.date_list[task.date_list.length - 1]).format('L');
    let note = t('risk.activities.blocked', 'Activity Blocked. Ends {{dueDate}}.', {dueDate});
    if (notStarted) {
      note = t('risk.activities.blockednotstarted', 'Activity Blocked. Starts on {{startDate}}.', {startDate});
    } else if (overdue) {
      note = t('risk.activities.blockedoverdue', 'Activity Blocked. Overdue {{dueDate}}.', {dueDate});
    }

    return {
      taskName: task.name,
      wbs: getWBSText(task.outline_sort_key, oskMap),
      notes: [note],
      sub: subMap[task.responsible_org_id] || '',
      icons: [{iconName: 'blocked'}],
    } as SectionRow;
  });

  return rows;
};

type SectionProps = {
  title: string;
  rows: SectionRow[];
  category?: TaskLabelCategory;
  showCompany: boolean;
  t: TFunction;
};

export const Section = ({title, rows, showCompany, t}: SectionProps) => {
  const [showMore, setShowMore] = useState(false);
  const publicURL = window.location.origin;

  if (!rows || !rows.length) {
    return null;
  }

  const DEFAULT_ROWS = 3;
  const visibleRows = showMore ? rows : rows.slice(0, DEFAULT_ROWS);

  return (
    <>
      <div className={s.section_title}>{title}:</div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>{t('risk.headers.activity_name', 'Activity Name')}</th>
            {showCompany ? <th>{t('risk.headers.company', 'Company')}</th> : null}
            <th>{t('risk.headers.notes', 'Notes')}</th>
          </tr>
        </thead>
        <tbody>
          {visibleRows.map((row, idx) => (
            <tr key={`${idx}`}>
              <td className={s.icon_cell}>
                <span className={s.icon_block}>
                  {row.icons?.map((icon, idx) => (
                    <span key={idx} style={{color: icon.color}}>
                      {icon.iconName === 'blocked' ? (
                        <TaskStatusIcon name={TaskStatusType.blocked} />
                      ) : (
                        <Icon colorFill titleText={icon.title} name={icon.iconName} origin={publicURL} />
                      )}
                    </span>
                  ))}
                </span>
              </td>
              <td className={s.name_cell}>
                <div className={s.activity_name}>{row.taskName}</div>
                <div className={s.metadata}>{row.wbs}</div>
              </td>
              {showCompany ? <td className={s.activity_sub}>{row.sub}</td> : null}
              <td>{row.notes.join(t('risk.labels.item_separator', '; '))}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {rows.length > visibleRows.length && !showMore ? (
        <div className={s.show_more_container}>
          <CtrlButton className={s.show_more_bt} color="second" onClick={() => setShowMore(true)}>
            {t('risk.controls.show_more', 'show {{remaining}} more', {remaining: rows.length - DEFAULT_ROWS})}
          </CtrlButton>
        </div>
      ) : null}
    </>
  );
};

export const getLegend = (t: TFunction) => {
  const publicURL = window.location.origin;
  return (
    <div className={s.legend}>
      <h3>{t('risk.headers.legend')}</h3>
      <div className={s.legend_row}>
        <span style={{color: '#9B120D'}}>
          <Icon colorFill name="exclamation" origin={publicURL} />
        </span>
        {t('risk.legend.safety')}
      </div>
      <div className={s.legend_row}>
        <span style={{color: '#9B120D'}}>
          <Icon colorFill name="circlex" origin={publicURL} />
        </span>
        {t('risk.legend.pita')}
      </div>
      <div className={s.legend_row}>
        <span style={{color: 'black'}}>
          <Icon colorFill name="startdate" origin={publicURL} />
        </span>
        {t('risk.legend.activity_starting')}
      </div>
      <div className={s.legend_row}>
        <span style={{color: 'black'}}>
          <Icon colorFill name="enddate" origin={publicURL} />
        </span>
        {t('risk.legend.activity_ending')}
      </div>
    </div>
  );
};
