import * as Sentry from '@sentry/react';
import {AxiosResponse} from 'axios';

import FeedbackService from 'api/feedback';
import TasksApi from 'api/tasks';
import {container} from 'IocContainer';
import {Activity, LoadOptions} from 'modules/Tasks/components/Gantt/types';
import {transformIssuesData} from 'modules/Tasks/components/Gantt/utils/functions';
import {SortOrder} from 'shared/constants/common';
import {fetchAllGenerator, fetchAllWithGenerator} from 'shared/helpers/axios';
import {addStatusToFilters} from 'shared/helpers/tasks';
import {MessageTypeMap} from 'shared/models/feedback';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskProjection} from 'shared/models/task/const';
import {TaskListMinimalModel, IssueModelRawDTO, FeedbackProjectModelDTO} from 'shared/models/task/task';
import {UIStoreType} from 'shared/stores/UIStore';

export async function loadActivityIds({queryParams, projectId, dataRange, done, setLoading}: LoadOptions) {
  setLoading(true);

  try {
    const results: TaskListMinimalModel[] = [];

    for await (const batch of fetchAllGenerator<TaskListMinimalModel>({
      request: async (offset: number, take: number) => {
        return TasksApi.getProjectTasks({
          projection: TaskProjection.taskMinimal,
          offset: offset,
          limit: take,
          sortField: 'outline_sort_key',
          sortOrder: SortOrder.ASC,
          includeSummaryTasks: true,
          params: addStatusToFilters(queryParams.state, {
            ...queryParams,
            objectTypeList: queryParams.milestonesOnly ? ['milestone'] : ['task', 'summary', 'activity', 'milestone'],
            projectId,
            schedIntersect: dataRange?.map((date) => date.toISOString()) as [string, string],
          }),
        });
      },
      initialTake: 500,
      maxTake: 500,
      maxRetries: 3,
      retryDelay: 2_000,
      initialParallelCalls: 5,
      maxParallelCalls: 7,
    })) {
      results.push(...batch);
    }

    const activityIds = results.map((task) => task.id);
    done(activityIds);
    return results;
  } catch (error) {
    throw error;
  } finally {
    setLoading(false);
  }
}

export async function loadIssues({queryParams, projectId, dataRange, done, setLoading}: LoadOptions) {
  const uiStore = container.get<UIStoreType>(IOC_TYPES.UIStore);
  uiStore.setLoading(true);
  setLoading(true);

  try {
    const allIssues = await fetchAllWithGenerator<IssueModelRawDTO>({
      request: async (offset: number, take: number) => {
        return TasksApi.getTaskIssues(projectId, {
          offset: offset,
          limit: take,
          sortParams: {
            sortField: 'description',
            sortOrder: SortOrder.DESC,
          },
          filterParams: {
            ...queryParams,
            schedIntersect: dataRange?.map((date) => date.toISOString()) as [string, string],
          },
        });
      },
      initialTake: 100,
      maxTake: 500,
      maxRetries: 3,
      retryDelay: 2_000,
      initialParallelCalls: 5,
      maxParallelCalls: 7,
    });

    const feedbackResults = await fetchAllWithGenerator<{id: string; feedback: FeedbackProjectModelDTO[]}>({
      request: async (offset: number, take: number) => {
        const issuesSlice = allIssues.slice(offset, offset + take);

        try {
          const allFeedback = await FeedbackService.getAllFeedBackForProject(projectId);

          // Explicitly type the results array
          const resultsData: Array<{id: string; feedback: FeedbackProjectModelDTO[]}> = issuesSlice.map((issue) => {
            const issueFeedback = allFeedback.filter((fb) => fb.taskId === issue.id);

            const filteredFeedback = issueFeedback.filter((fbr) => {
              if (
                fbr?.payload?.extChatEvent &&
                'event' in fbr?.payload?.extChatEvent &&
                (fbr.payload.extChatEvent?.event?.type === 'm.room.message.text' ||
                  fbr.payload.extChatEvent?.event?.type === 'm.room.message')
              ) {
                return false;
              }

              return (
                fbr.feedbackType === MessageTypeMap.message ||
                fbr.feedbackType === MessageTypeMap.image ||
                fbr.feedbackType === MessageTypeMap.extChatEvent
              );
            });

            return {id: issue.id, feedback: filteredFeedback};
          });

          // Return with proper AxiosResponse structure
          return {
            data: resultsData,
          } as AxiosResponse<typeof resultsData>;
        } catch (error) {
          Sentry.captureException('Failed to fetch all issue feedback:', error);
          const emptyResults = issuesSlice.map((issue) => ({id: issue.id, feedback: []}));
          return {
            data: emptyResults,
          } as AxiosResponse<typeof emptyResults>;
        }
      },
      initialTake: 10,
      maxTake: 50,
      maxRetries: 3,
      retryDelay: 2_000,
      initialParallelCalls: 3,
      maxParallelCalls: 5,
    });

    const feedbackMap = new Map(feedbackResults.map((result) => [result.id, result.feedback] as const));

    const ganttData = transformIssuesData({
      tasks: allIssues,
      projectId,
      flatList: false,
      feedbackMap,
    });

    const allTaskIds = new Set<string>();
    ganttData.tasks.forEach((issue) => issue.task_ids.forEach((taskId) => allTaskIds.add(taskId)));

    const tasksDetails = await fetchAllWithGenerator({
      request: async (offset: number, take: number) => {
        const taskIds = Array.from(allTaskIds).slice(offset, offset + take);
        const tasks = await TasksApi.getTasks(taskIds);
        return {
          data: tasks,
        } as AxiosResponse<typeof tasks>;
      },
      initialTake: 50,
      maxTake: 500,
      maxRetries: 3,
      retryDelay: 2_000,
      initialParallelCalls: 5,
      maxParallelCalls: 7,
    });

    const taskIdToNameMap = new Map<string, string>();
    tasksDetails.forEach((task) => taskIdToNameMap.set(task.id, task.name));

    const tasks = ganttData.tasks.map((issue) => ({
      ...issue,
      activities: issue.task_ids.reduce((acc, taskId) => {
        const taskName = taskIdToNameMap.get(taskId);
        if (taskName) {
          acc.push({id: taskId, name: taskName});
        }
        return acc;
      }, [] as Activity[]),
    }));

    done({links: ganttData.links, tasks});
    return allIssues;
  } catch (error) {
    throw error;
  } finally {
    setLoading(false);
    uiStore.setLoading(false);
  }
}
