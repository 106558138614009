import {SerializedError} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import cn from 'classnames';
import {Field, FieldArray, FieldProps, Form, Formik, FormikProps} from 'formik';
import {TFunction} from 'i18next';
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';

import ImportApi from 'api/import';
import {GanttColumnConfig} from 'modules/Tasks/components/Gantt/hooks/useGanttColumns';
import {GANTT_COLUMNS_SETTINGS} from 'modules/Tasks/components/Gantt/utils/constants';
import Button from 'shared/components/Button';
import FormControl from 'shared/components/CoreForm/FormControl';
import CoreSelect from 'shared/components/CoreForm/Select/Select';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import Switcher from 'shared/components/CoreForm/Switcher';
import FormikChangeWatcher from 'shared/components/FormikChangeWatcher';
import Icon from 'shared/components/Icon';
import Popup from 'shared/components/Popup/Popup';
import TaskCard from 'shared/components/TaskCard';
import {
  TaskImportClientMapping,
  TaskImportField,
  TaskImportPrepResponse,
  TaskImportProjectCustomFieldDef,
  TasksImportConfig,
} from 'shared/components/TasksImport/utils/types';
import env from 'shared/constants/env';
import {IconsMap} from 'shared/constants/icons';
import {MixpanelEventTypes} from 'shared/constants/mixpanelEvents';
import {toTitleCase} from 'shared/helpers/common';
import {assumeTimezoneDateAsLocal, toIsoString} from 'shared/helpers/dates';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useLocalStorage} from 'shared/hooks/useLocalStorage';
import {useProfile} from 'shared/hooks/useProfile';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useQueryCache} from 'shared/hooks/useQueryCache/useQueryCache';
import {ProjectCustomFieldType} from 'shared/models/project';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {useRootDispatch, useRootSelector} from 'store';
import {selectAllProjects} from 'store/projects';
import {updateProject} from 'store/projects/actions';

import {useTasksImportContext} from '../../TasksImportContext/TasksImportContext';
import {TaskImportStep, TasksImportSourceType} from '../../utils/constants';
import {isCSVFile, isMppFile} from '../../utils/functions';
import s from '../styles.module.scss';

import {processCustomFieldsApi, updateImportSettings} from './customFieldImportHelpers';
import {getDefaultValues, getMapping, schema, validateCustomFieldName} from './schema';

import './style.css';

export type ImportSubmitValues = {
  asyncUploadId: string;
  importedCustomFields: TaskImportProjectCustomFieldDef[];
  values: TasksImportConfig;
};

type TasksImportMappingProps = {
  customColumns: TaskImportProjectCustomFieldDef[];
  defaultProjectId?: string;
  fields: TaskImportField[];
  fileHeaders: {label: string; value: string}[];
  importPercentage: number;
  mixpanelEvents: MixpanelEventTypes['tasks']['import'];
  onProjectChange: (projId: string) => void;
  onSubmit: (values: ImportSubmitValues) => Promise<void>;
  parseResult: TaskImportPrepResponse;
};

const getDateFormatOptions = (t: TFunction): CoreOptionType[] => [
  {label: t('import:mapping.date_options.month_first', 'MM/DD/YYYY'), value: 'MM/DD/YYYY'},
  {label: t('import:mapping.date_options.day_first', 'DD/MM/YYYY'), value: 'DD/MM/YYYY'},
  {label: t('import:mapping.date_options.year_first', 'YYYY-MM-DD'), value: 'YYYY-MM-DD'},
];

const TasksImportMapping: FC<TasksImportMappingProps> = ({
  customColumns,
  defaultProjectId,
  fields,
  fileHeaders,
  mixpanelEvents,
  onProjectChange,
  onSubmit,
  parseResult,
}) => {
  const dispatch = useRootDispatch();
  const {companyName} = useCompany();
  const worker = useProfile();
  const projects = useRootSelector(selectAllProjects);
  const [isParsing, setParsing] = useState(false);
  const [tasksIsLoading, setTasksIsLoading] = useState(false);
  const [taskPreviewModel, setTaskPreviewModel] = useState<Partial<TaskDetailsModelDTO>>();
  const [mappingValues, setMappingValues] = useState<TaskImportClientMapping | Record<string, unknown>>({});
  const {t} = useTranslation('import');

  const [{sourceType, procoreProjects, file, asyncUploadId}, actions] = useTasksImportContext();

  const [selectedProject, setSelectedProject] = useState<string>(defaultProjectId);
  const [selectedProcoreProject, setSelectedProcoreProject] = useState<string>(procoreProjects?.[0]?.value);
  const formik = useRef<FormikProps<TasksImportConfig>>();
  const {cacheHelper} = useQueryCache();
  const {mixpanel} = useAnalyticsService();
  const project = useProjectSelector(selectedProject);
  const mixpanelMeta = useMemo(() => Object.assign({}, {'Project Name': project?.name}), [project]);
  const dateFormatOptions = useMemo(() => getDateFormatOptions(t), [t]);

  const isLoading = !parseResult || isParsing || (sourceType === TasksImportSourceType.proCore && !procoreProjects);

  const [getStoredSettings, storeColumnsConfig] = useLocalStorage<GanttColumnConfig[]>({
    key: GANTT_COLUMNS_SETTINGS,
    path: `${worker.id}.${selectedProject}.gantt`,
    defaultValue: null,
    enabled: !!selectedProject && !!worker.id,
  });

  const mailto = `${t(
    'mapping.mailto.start',
    'mailto:c4@bycore.com?subject=Help with Import Activities File&body=',
  )}${companyName}${t(
    'mapping.mailto.end',
    ' would like help with Importing Activities File. Attach the file you are trying to import',
  )}`;

  const fieldsToSelect = useMemo(() => {
    // required if targetedAmount is selected
    const completionUnit = fields.find((field) => field.key === 'completionUnit');
    if (completionUnit) {
      completionUnit.required = !!mappingValues.completionTarget;
    }
    // Only show predecessors if uniqueId is not 'auto'
    if (file && isCSVFile(file))
      return fields.filter(({key}) => {
        if (key === 'predecessors') {
          return mappingValues.uniqueId !== 'auto';
        }
        return true;
      });
    if (file && !isMppFile(file.name)) return fields.filter(({key}) => key !== 'predecessors');
    // we are automatically mapping for the outlineCode field
    // if file is mpp and hasHierarchy = true
    return fields.filter(({key}) => key !== 'outlineCode' && key !== 'predecessors');
  }, [fields, file, mappingValues.completionTarget, mappingValues.uniqueId]);

  const onFormikValueChanged = useCallback(
    (values: TasksImportConfig) => {
      if (parseResult?.result?.samples?.length) {
        const taskSamples = parseResult.result.samples;
        const relevantTaskSample =
          taskSamples.find((sample) => Object.values(sample).every((value) => value !== null)) || taskSamples[0];
        setTaskPreviewModel({
          uniqueId: relevantTaskSample[values.mapping.uniqueId],
          status: TaskStatusType.tba,
          name: relevantTaskSample[values.mapping.name],
          schedStartDate: toIsoString(
            assumeTimezoneDateAsLocal(relevantTaskSample[values.mapping.schedStartDate], project?.timezone),
          ),
          schedEndDate: toIsoString(
            assumeTimezoneDateAsLocal(relevantTaskSample[values.mapping.schedEndDate], project?.timezone),
          ),
          location: relevantTaskSample[values.mapping.location],
          responsibleParty: relevantTaskSample[values.mapping.subcontractor],
          outlineCode: relevantTaskSample[values.mapping.outlineCode],
        });
      }
      setMappingValues(values.mapping);
    },
    [parseResult?.result.samples, project?.timezone],
  );

  const taskIdOptions = useMemo(
    () =>
      fileHeaders
        .concat({label: t('fields.auto_assign.label', 'Auto-Assign Task ID'), value: 'auto'})
        .sort((a, b) => a.label.localeCompare(b.label, 'en', {sensitivity: 'base'})),
    [fileHeaders],
  );

  const projectsOptions = useMemo(
    () => projects.map((project) => ({label: project.name, value: project.id})),
    [projects],
  );

  const startFileImport = async (defaultProject: string, selectedFile: File) => {
    setParsing(true);
    const {
      data: {id, putUrl},
    } = await ImportApi.startImport(defaultProject, selectedFile.name);
    actions.asyncUploadId(id);

    await ImportApi.uploadFileToS3(
      selectedFile,
      process.env.NODE_ENV === 'development'
        ? putUrl.replace('https://journey-builders-staging.s3.amazonaws.com/', '/s3/')
        : putUrl,
    );
    await startPrep(defaultProject, id);
    setParsing(false);
  };

  const startProcoreImport = async (projectId: string, procoreProjectId: string) => {
    setParsing(true);
    const res = await ImportApi.startImport(projectId);
    actions.asyncUploadId(res.data.id);
    await ImportApi.extract(selectedProject, res.data.id, procoreProjectId);
    await ImportApi.pollExtractResult(selectedProject, res.data.id);
    await startPrep(projectId, res.data.id);
    setParsing(false);
  };

  const startPrep = async (projectId: string, importId: string) => {
    await ImportApi.prepRun(projectId, importId);
    const prepData = await ImportApi.pollPrepResults(projectId, importId, 100);
    handleStatusResult(prepData);
  };

  const handleStatusResult = (rs: TaskImportPrepResponse) => {
    if (rs.status === 'finished') {
      if (rs.result.errors) {
        actions.setErrorCode(rs.result.errors[0].code);
      } else if (rs.result.error) {
        actions.setErrorCode('500');
      } else {
        const tmp = Object.assign(rs, {
          result: {
            ...rs.result,
            headers: rs.result.headers.filter(Boolean).sort(new Intl.Collator('en', {sensitivity: 'base'}).compare),
          },
        });
        actions.setParseResult(tmp);
      }
    }
  };

  useEffect(() => {
    if (projects.length && formik.current) {
      formik.current.setFieldValue('defaultProject', selectedProject ? selectedProject : projects[0].id);
    }
  }, [projects, selectedProject]);

  const saveMapping = async (values: TasksImportConfig) => {
    const updatingProject = projects.find((project) => project.id === values.defaultProject);
    const {mapping} = values;

    if (file && isMppFile(file.name)) {
      if (values.importWithHierarchy) {
        mapping.outlineCode = 'outline_code';
      } else {
        delete mapping.outlineCode;
      }
    }

    const preparedMapping = JSON.stringify({mapping: mapping, dateFormat: values.dateFormat});
    if (updatingProject.importSettings === preparedMapping) {
      return;
    }
    const res = await dispatch(
      updateProject({
        ...updatingProject,
        importSettings: preparedMapping,
      }),
    );
    if (updateProject.rejected.match(res)) {
      throw new Error(res.error.message);
    } else {
      return res.payload;
    }
  };

  const {mutateAsync: handleSaveMapping} = useMutation(saveMapping, {
    onError: (err: SerializedError) => {
      toast.error(err.message);
      Sentry.captureException(err);
    },
    onSuccess: (res, values) => {
      actions.setConfig(values);
      toast.success(t('toast.success.save', 'Project mapping saved!'));
      cacheHelper.findRecentPagedQuery('project')?.setData((data) => cacheHelper.updatePagedData(data, res));
    },
  });

  const saveCustomFields = async (values: TasksImportConfig) => {
    try {
      if (!values.customFields.length) return;
      const {customFields} = values;
      const updatingProject = projects.find((project) => project.id === values.defaultProject);

      if (!updatingProject) {
        throw new Error('Project not found');
      }

      const {updated, created} = await processCustomFieldsApi(
        customFields,
        values.defaultProject,
        updatingProject.customFieldDef,
      );

      const existingCustomFields = customFields.filter((field) => !!field.internalFieldName);
      const preparedMapping = updateImportSettings(
        updatingProject.importSettings,
        customFields,
        existingCustomFields,
        created,
      );

      if (created.length > 0) {
        const existingConfig = getStoredSettings() || [];
        const newColumnSettings: GanttColumnConfig[] = [
          ...existingConfig,
          ...created.map(({internalFieldName}) => ({
            name: internalFieldName,
            hideByUser: false,
            defaultHide: true,
            touched: false,
            isCustom: true,
          })),
        ];
        storeColumnsConfig(newColumnSettings);
      }

      const updatedCustomFieldDef = project.customFieldDef.map((field) => {
        const updatedField = updated.find((u) => u.internalFieldName === field.internalFieldName);
        return updatedField || field;
      });

      const mergedCustomFieldDef = [...updatedCustomFieldDef, ...created];

      await dispatch(
        updateProject({
          ...updatingProject,
          importSettings: preparedMapping,
          customFieldDef: mergedCustomFieldDef,
        }),
      );

      const fieldsToImport = customFields.map((field) => {
        const normalizedFieldName = toTitleCase(field.fieldName.trim().replace(/\s+/g, ' '));
        const internalFieldName = [...updated, ...created].find(
          (cfRes) => cfRes.fieldName === normalizedFieldName,
        ).internalFieldName;

        return {...field, fieldName: normalizedFieldName, internalFieldName};
      });

      return fieldsToImport || [];
    } catch (error) {
      throw error;
    }
  };

  const {mutateAsync: handleSaveCustomFields} = useMutation(saveCustomFields, {
    onError: (err: Error) => {
      toast.error(t('errors.messages.custom_column'));
      Sentry.captureException(err);
    },
    onSuccess: (fieldsToImport) => {
      if (!fieldsToImport) {
        return;
      }
      const values = formik.current.values;
      formik.current.setValues({...values, customFields: fieldsToImport});
      toast.success(t('toast.success.custom_column'));
    },
  });

  const handleSubmit = async (values: TasksImportConfig) => {
    await handleSaveMapping(values);
    const importedCustomFields = await handleSaveCustomFields(values);
    setTasksIsLoading(true);
    await onSubmit({asyncUploadId, importedCustomFields: importedCustomFields || [], values});
    setTasksIsLoading(false);
  };

  useEffect(() => {
    if (!parseResult) return;
  }, [parseResult]);

  useEffect(() => {
    if (sourceType === TasksImportSourceType.file) {
      startFileImport(selectedProject, file);
    } else if (selectedProcoreProject) {
      startProcoreImport(selectedProject, selectedProcoreProject);
    }
  }, [sourceType, selectedProcoreProject]);

  useEffect(() => {
    if (!selectedProcoreProject && procoreProjects) {
      setSelectedProcoreProject(procoreProjects[0]?.value);
    }
  }, [procoreProjects, selectedProcoreProject]);

  useEffect(() => {
    if (parseResult?.result?.hasHierarchy) {
      const values = formik.current.values;
      formik.current.setValues({
        ...values,
        importWithHierarchy: true,
        mapping: {...values.mapping, outlineCode: 'outline_code'},
      });
    }
  }, [parseResult?.result?.hasHierarchy]);

  // Add effect to clear predecessors when uniqueId is set to 'auto'
  useEffect(() => {
    if (mappingValues.uniqueId === 'auto') {
      formik.current?.setFieldValue('mapping.predecessors', null);
    }
  }, [mappingValues.uniqueId]);

  const initialValues: TasksImportConfig = useMemo(() => {
    let mapping = getDefaultValues(dateFormatOptions[0].value).mapping;

    const defaultProject = selectedProject || (projects.length ? projects[0].id : '');

    try {
      const project = projects.find((project) => project.id === selectedProject);
      if (project && project.importSettings) {
        const importSettings = JSON.parse(project.importSettings);
        mapping = getMapping(mapping, importSettings.mapping);
        return {
          ...getDefaultValues(dateFormatOptions[0].value),
          defaultProject: selectedProject,
          customFields: customColumns,
          mapping,
        };
      }
    } catch (error) {
      if (env.NODE_ENV === 'development') {
        console.warn(error);
      }
    }

    return {
      ...getDefaultValues(dateFormatOptions[0].value),
      defaultProject,
      customFields: customColumns,
      mapping,
    };
  }, [dateFormatOptions, selectedProject, projects, customColumns]);

  return (
    <Formik<TasksImportConfig>
      enableReinitialize
      initialValues={initialValues}
      innerRef={formik}
      onSubmit={handleSubmit}
      validationSchema={schema(t)}
      validateOnBlur
    >
      {({setFieldValue, submitForm, values}) => (
        <>
          <FormikChangeWatcher onChange={onFormikValueChanged} />
          <Popup.Body>
            <div className="compare-grid">
              <div className="compare-grid__content">
                <Form className="form-compare">
                  {parseResult?.result?.hasHierarchy && (
                    <div className="form-default__item form-default__item--full">
                      <Field name="importWithHierarchy">
                        {({field}: FieldProps) => (
                          <Switcher
                            {...field}
                            onChange={(value) => {
                              const event = value ? mixpanelEvents.toggleWBSOn : mixpanelEvents.toggleWBSOff;
                              mixpanel.trackWithAction(() => setFieldValue(field.name, value), event);
                            }}
                            disabled={isLoading}
                            label={t('mapping.form.hierarchy.label', 'Import With Hierarchy (WBS)')}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                  <div className="form-compare__default">
                    <div className="form-compare__item form-compare__item--default">
                      <FormControl
                        name="dateFormat"
                        label={t('mapping.form.date_format.label', 'Select Date Format You Use')}
                      >
                        <Field>
                          {({field}: FieldProps) => (
                            <CoreSelect
                              options={dateFormatOptions}
                              value={field.value}
                              onChange={(value) =>
                                mixpanel.trackWithAction(
                                  () => setFieldValue(field.name, value),
                                  mixpanelEvents.selectDateFormat,
                                  mixpanelMeta,
                                )
                              }
                            />
                          )}
                        </Field>
                      </FormControl>
                    </div>
                    <div className="form-compare__item form-compare__item--default">
                      <FormControl
                        name="defaultProject"
                        label={t('mapping.form.default_project.label', 'Default Project')}
                      >
                        <Field>
                          {({field}: FieldProps) => (
                            <CoreSelect
                              options={projectsOptions}
                              value={field.value}
                              onChange={(value) =>
                                mixpanel.trackWithAction(
                                  () => {
                                    setFieldValue(field.name, value);
                                    setSelectedProject(value);
                                    onProjectChange(value);
                                  },
                                  mixpanelEvents.selectProject,
                                  {'Project Name': value},
                                )
                              }
                            />
                          )}
                        </Field>
                      </FormControl>
                    </div>
                    {sourceType === TasksImportSourceType.proCore && (
                      <div className="form-compare__item form-compare__item--default">
                        <FormControl
                          name="procoreProject"
                          label={t('mapping.form.procore_project.label', 'Procore Project')}
                        >
                          <CoreSelect
                            options={procoreProjects}
                            isLoading={!procoreProjects}
                            loadingMessage={() => t('mapping.form.procore_project.loading', 'Loading...')}
                            value={selectedProcoreProject}
                            onChange={(value) =>
                              mixpanel.trackWithAction(
                                () => {
                                  setSelectedProcoreProject(value);
                                },
                                mixpanelEvents.selectProject,
                                {'Project Name': value},
                              )
                            }
                          />
                        </FormControl>
                      </div>
                    )}
                  </div>
                  <div className="form-compare__body">
                    {fieldsToSelect.map((importField) => (
                      <div key={importField.key} className="form-compare__control">
                        <span className="form-compare__name">
                          {importField.label}{' '}
                          {!!importField.required && <span>*{t('mapping.form.symbol.required', '*')}</span>}
                        </span>
                        <Icon
                          colorFill
                          className="form-compare__icon-compare"
                          size={24}
                          name={IconsMap.arrow_forward}
                        />
                        <div className="form-compare__item form-compare__item--compare">
                          <FormControl name={`mapping.${importField.key}`} labelHidden label={importField.label}>
                            <Field>
                              {({field}: FieldProps) => (
                                <CoreSelect
                                  placeholder={
                                    isLoading
                                      ? t('mapping.form.select.placeholder.loading', 'Preparing data...')
                                      : t('mapping.form.select.placeholder.default', 'Select...')
                                  }
                                  isSearchable
                                  isDisabled={!parseResult}
                                  isLoading={isLoading}
                                  options={importField.key === 'uniqueId' ? taskIdOptions : fileHeaders}
                                  isOptionDisabled={(option) => Object.values(mappingValues).includes(option.value)}
                                  value={field.value}
                                  onChange={(value) => {
                                    const setValue = () => setFieldValue(field.name, value);
                                    if (importField.key === 'shouldImport') {
                                      mixpanel.trackWithAction(setValue, mixpanelEvents.shouldImportSelect);
                                    } else {
                                      setValue();
                                    }
                                  }}
                                  menuPlacement="auto"
                                  isClearable
                                />
                              )}
                            </Field>
                          </FormControl>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="form-compare__body">
                    <FieldArray
                      name="customFields"
                      render={(helpers) => (
                        <>
                          <div className={s['custom-column-heading']}>
                            <h3>{t('mapping.form.custom_column.heading')}</h3>
                            <Button
                              className={s['custom-column-heading--cta']}
                              buttonSize="m"
                              icon={<Icon className={s['custom-column-heading--cta-icon']} name={IconsMap.plus} />}
                              onClick={() => {
                                mixpanel.track(mixpanelEvents.addCustomColumn);
                                helpers.push<TaskImportProjectCustomFieldDef>({
                                  fieldData: '',
                                  fieldName: '',
                                  fieldType: ProjectCustomFieldType.string,
                                  internalFieldName: '',
                                  mappedTo: null,
                                });
                              }}
                            >
                              {t('mapping.form.custom_column.add_custom_column')}
                            </Button>
                          </div>
                          {values.customFields?.length > 0
                            ? values.customFields.map((col, idx) => (
                                <div key={`customFields.${idx}`} className="form-compare__control">
                                  <FormControl name={`customFields.${idx}.fieldName`} className="form-compare__name">
                                    <Field
                                      name={`customFields.${idx}.fieldName`}
                                      validate={(val: string) =>
                                        validateCustomFieldName({col, idx, projects, t, val, values}, fields)
                                      }
                                    >
                                      {({field}: FieldProps) => (
                                        <input
                                          {...field}
                                          value={field.value}
                                          onChange={field.onChange}
                                          onBlur={field.onBlur}
                                          placeholder={t('mapping.form.custom_column.input_placeholder', 'Column Name')}
                                          maxLength={20}
                                          className={cn('form-compare__name', s['custom-column-input'])}
                                        />
                                      )}
                                    </Field>
                                  </FormControl>
                                  <Icon
                                    colorFill
                                    className="form-compare__icon-compare"
                                    size={24}
                                    name="arrow_forward"
                                  />
                                  <div
                                    className={cn(
                                      'form-compare__item form-compare__item--compare',
                                      s['custom-column-label'],
                                    )}
                                  >
                                    <div className={s['custom-column-select-wrapper']}>
                                      <FormControl
                                        name={`customFields.${idx}.mappedTo`}
                                        labelHidden
                                        label={t('mapping.form.custom_column.select_label')}
                                      >
                                        <Field name={`customFields.${idx}.mappedTo`}>
                                          {({field}: FieldProps) => {
                                            const hasUniqueId = fieldsToSelect.some(
                                              (field) => field.key === 'uniqueId',
                                            );
                                            const options = hasUniqueId
                                              ? taskIdOptions.filter((item) => item.value !== 'auto')
                                              : fileHeaders;
                                            return (
                                              <CoreSelect
                                                {...field}
                                                isClearable
                                                isDisabled={!col.fieldName}
                                                isSearchable
                                                menuPlacement="auto"
                                                onChange={(newValue: ProjectCustomFieldType) => {
                                                  mixpanel.trackWithAction(
                                                    () => setFieldValue(field.name, newValue),
                                                    mixpanelEvents.customMappedTo,
                                                    {[col.fieldName]: newValue},
                                                  );
                                                }}
                                                options={options}
                                                placeholder={
                                                  isLoading
                                                    ? t('mapping.form.select.placeholder.loading', 'Preparing data...')
                                                    : t('mapping.form.select.placeholder.default', 'Select...')
                                                }
                                                value={field.value}
                                              />
                                            );
                                          }}
                                        </Field>
                                      </FormControl>
                                    </div>
                                    {!col.internalFieldName ? (
                                      <Button
                                        buttonSize="xl"
                                        iconOnly
                                        icon={
                                          <Icon
                                            className={cn(s['custom-column-delete'], {
                                              [s['custom-column-delete--is-submitting']]: isLoading,
                                            })}
                                            size={24}
                                            name={IconsMap.delete}
                                          ></Icon>
                                        }
                                        onClick={() => {
                                          helpers.remove(idx);
                                          mixpanel.track(mixpanelEvents.deleteCustomColumn);
                                        }}
                                      >
                                        {t('mapping.form.custom_column.delete')}
                                      </Button>
                                    ) : null}
                                  </div>
                                </div>
                              ))
                            : null}
                        </>
                      )}
                    />
                  </div>
                </Form>
              </div>
              <div className="compare-grid__aside">
                <h2 className="compare-preview__title">{t('mapping.preview.title', 'Preview')}</h2>
                <div className="compare-preview__description">
                  {t('mapping.preview.description', 'This is how your single activity will look like')}
                </div>
                <TaskCard
                  task={taskPreviewModel}
                  initialDateFormat={formik?.current?.values.dateFormat}
                  project={projects.find((proj) => proj.id === formik.current?.values?.defaultProject)}
                  className="compare-preview__card"
                />
                <a
                  className="ctrl-btn ctrl-btn--view-border compare-grid__button-help"
                  href={mailto}
                  onClick={() => mixpanel.track(mixpanelEvents.needHelpBtn, mixpanelMeta)}
                >
                  <Icon colorFill className="ctrl-btn__icon" name="help" />
                  <span className="ctrl-btn__text">{t('mapping.preview.help', 'Need help importing activities?')}</span>
                </a>
              </div>
            </div>
          </Popup.Body>
          <Popup.Footer>
            <Button
              disabled={isLoading || tasksIsLoading}
              className={`popup__button ${tasksIsLoading ? 'is-processing' : ''}`}
              type="button"
              onClick={() =>
                mixpanel.trackWithAction(
                  () => actions.setCurrentStep(TaskImportStep.SelectSource),
                  mixpanelEvents.nextBtn,
                  mixpanelMeta,
                  !tasksIsLoading,
                )
              }
            >
              {t('mapping.buttons.prev', 'Prev')}
            </Button>
            <Button
              data-cy="btnImportNext"
              disabled={isLoading || tasksIsLoading}
              icon={tasksIsLoading ? <Icon colorFill className="ctrl-btn__icon" name="autorenew" /> : null}
              className={`popup__button ${tasksIsLoading ? 'is-processing' : ''}`}
              type="button"
              onClick={() =>
                mixpanel.trackWithAction(submitForm, mixpanelEvents.nextBtn, mixpanelMeta, !tasksIsLoading)
              }
            >
              {tasksIsLoading ? t('mapping.buttons.loading', 'Preparing...') : t('mapping.buttons.next', 'Next')}
            </Button>
          </Popup.Footer>
        </>
      )}
    </Formik>
  );
};
export default TasksImportMapping;
