import {ChangeEventHandler, FunctionComponent, useState} from 'react';
import {toast} from 'react-toastify';

import SubscriptionApi from 'api/subscription';
import Button from 'shared/components/Button';
import {useConfirm} from 'shared/components/Confirmation';
import Popup from 'shared/components/Popup/Popup';

import {useSubscriptionContext} from '../CheckoutContext';
import {subscriptionActionsCreators, SubscriptionStep} from '../state';

const CancelReason: FunctionComponent = () => {
  const {
    company,
    state: {subscription},
    dispatch,
    onCanceled,
  } = useSubscriptionContext();
  const [reason, setReason] = useState('');
  const [isCanceling, setIsCanceling] = useState(false);
  const {confirm} = useConfirm();

  const cancelSubscription = async () => {
    if (await confirm('Are you sure you want to unsubscribe?')) {
      setIsCanceling(true);
      try {
        await SubscriptionApi.cancelSubscription(company.id, subscription.id, reason);
        onCanceled();
      } catch (error) {
        toast.error('Failed to unsubscribe');
        setIsCanceling(false);
      }
    } else {
      dispatch(subscriptionActionsCreators.setStep(SubscriptionStep.initial));
    }
  };

  const onTextChanged: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setReason(e.target.value);
  };

  return (
    <>
      <Popup.Body>
        <div className="form-plan form-plan--only">
          <h2 className="form-plan__title">We&apos;re sorry to see you go</h2>
          <div className="form-plan__description">
            <p>You can restore your plan any time.</p>
            <p>Your workers and activities will be there for you.</p>
          </div>
          <div className="ctrl-form ctrl-form--label-hidden" style={{marginTop: '16px'}}>
            <div className="ctrl-form__header">
              <label className="ctrl-form__label">Message</label>
            </div>
            <div className="ctrl-form__body">
              <textarea
                className="ctrl-textarea"
                placeholder="Tell Us Why You Cancel"
                maxLength={500}
                rows={8}
                value={reason}
                onChange={onTextChanged}
              />
            </div>
          </div>
        </div>
      </Popup.Body>
      <Popup.Footer className="popup__footer--center">
        <Button
          isLoading={isCanceling}
          disabled={isCanceling}
          className="ctrl-btn--color-success popup__button"
          onClick={() => cancelSubscription()}
        >
          Unsubscribe
        </Button>
      </Popup.Footer>
    </>
  );
};
export default CancelReason;
