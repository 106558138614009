import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {ExportToCSV} from 'services/ExportToCSV';
import Popup from 'shared/components/Popup/Popup';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';

import LegendCompare from './LegendCompare';

type TaskImportResultProps = {
  totals: {
    newTasks: number;
    edited: number;
    error: number;
    unChanged: number;
  };
  csv: string;
  onFinish: () => void;
};

const TaskImportResult: FC<TaskImportResultProps> = ({totals, csv, onFinish}) => {
  const {newTasks, error, edited, unChanged} = totals;

  const company = useCompany();
  const {mixpanel} = useAnalyticsService();
  const {t} = useTranslation('import');
  const mixpanelEvents = mixpanel.events.tasks.import;

  useEffectOnce(() => {
    mixpanel.track(mixpanelEvents.completeScreen);
  });

  function downloadNewTasks() {
    const csvExport = new ExportToCSV();
    csvExport.download(company.companyName + '_' + new Date().toLocaleString(), csv);
  }

  function goToMyTasks() {
    onFinish();
  }

  return (
    <Popup.Body>
      <div className="message-complete">
        <header className="message-complete__header">
          <h1 className="message-complete__title">{t('result.title', 'Import Complete')}</h1>
          <div
            className="message-complete__description"
            dangerouslySetInnerHTML={{
              __html: t('result.description', `You&apos;ve successfully imported your activities and ready to go!`),
            }}
          />
        </header>
        <picture className="picture message-complete__image" style={{marginTop: 18}}>
          <source srcSet="/images/other/complete-1@1x.png 1x, /images/other/complete-1@2x.png 2x" />
          <img className="picture__image" src="/images/other/complete-1@1x.png" />
        </picture>
        <LegendCompare className="message-complete__total" totals={{new: newTasks, error, edited, unChanged}} />
        <div className="message-complete__actions">
          <div className="message-complete__button-box">
            <button
              disabled={!csv}
              className="ctrl-btn ctrl-btn--color-light ctrl-btn--shadow message-complete__button"
              onClick={() => mixpanel.trackWithAction(downloadNewTasks, mixpanelEvents.downloadUpdated)}
              type="button"
            >
              <span className="ctrl-btn__text">{t('result.buttons.download', 'Download updated CSV')}</span>
            </button>
          </div>
          <div className="message-complete__button-box">
            <button
              data-cy="btnImportGoToTasks"
              className="ctrl-btn ctrl-btn--color-success ctrl-btn--shadow message-complete__button"
              onClick={() => mixpanel.trackWithAction(goToMyTasks, mixpanelEvents.goToMyTasks)}
              type="button"
            >
              <span className="ctrl-btn__text">{t('result.buttons.go_to_activities', 'Go to My Activities')}</span>
            </button>
          </div>
        </div>
      </div>
    </Popup.Body>
  );
};
export default TaskImportResult;
