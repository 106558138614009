import {useCallback, useEffect, useMemo} from 'react';
import {useQuery, useQueryClient} from 'react-query';

import ProjectsApi from 'api/projects';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {WakeCapCrews} from 'shared/models/task/task';

const useCrews = (projectId: string) => {
  const queryClient = useQueryClient();

  const fetchCrews = useCallback(() => ProjectsApi.getWakeCapCrews(projectId), [projectId]);

  const CREWS_CACHE_KEY = useMemo(() => [QUERY_CACHE_KEYS.crews, projectId], [projectId]);

  useEffect(() => {
    if (!queryClient.getQueryData(CREWS_CACHE_KEY)) {
      queryClient.prefetchQuery(CREWS_CACHE_KEY, fetchCrews);
    }
  }, [queryClient, CREWS_CACHE_KEY, fetchCrews]);

  const query = useQuery({
    queryKey: QUERY_CACHE_KEYS.crews(projectId),
    queryFn: fetchCrews,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
    initialData: queryClient.getQueryData<WakeCapCrews[]>(QUERY_CACHE_KEYS.crews(projectId)),
  });

  return query;
};

export default useCrews;
