import {GanttStatic} from 'dhtmlx-gantt';
import {observer} from 'mobx-react-lite';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory} from 'react-router';

import {container} from 'IocContainer';
import ExportDropdown from 'modules/Tasks/components/ActionsBar/components/ExportDropdown/ExportDropdown';
import {GanttSplitDropdown} from 'modules/Tasks/components/ActionsBar/GanttSplitDropdown';
import {useUndo} from 'modules/Tasks/components/ActionsBar/hooks/useUndo';
import {ActionGroup, ActionItem} from 'modules/Tasks/components/ActionsBar/types';
import {useTasksActions} from 'modules/Tasks/hooks/useTasksActions';
import {IconMapType} from 'modules/Tasks/types/icons';
import {OSK_SEGMENT_LENGTH} from 'modules/Tasks/utils/constants';
import {GanttSimpleLock} from 'modules/Tasks/utils/simpleLock';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import ProjectCollaboratePopup from 'shared/components/ProjectCollabPopup';
import ShareTask from 'shared/components/ShareTask';
import TasksImportPopup from 'shared/components/TasksImport/TasksImportPopup';
import {TaskImportObject} from 'shared/components/TasksImport/utils/types';
import {TasksViewMode} from 'shared/constants/common';
import {GanttNames} from 'shared/constants/gantt';
import {IconsMap} from 'shared/constants/icons';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useIsTablet} from 'shared/hooks/useIsTablet';
import Gantt from 'shared/models/Gantt';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskObjectType} from 'shared/models/task/const';
import {TaskFilterQuery} from 'shared/models/task/filter';
import {TaskRelativeDirection} from 'shared/models/task/task';
import {useInjectStore} from 'shared/providers/injection';
import {TasksStoreType} from 'shared/stores/TasksStore';
import {UIStoreType} from 'shared/stores/UIStore';
import {useRootDispatch} from 'store';
import {getProject} from 'store/projects/actions';

import BaselineDropdown from '../ActionsBar/components/BaselineDropdown/BaselineDropdown';
import ColorTasksModeBtn from '../ActionsBar/components/ColorByButton';
import ColorDropdown from '../ActionsBar/components/ColorDropdown/ColorDropdown';
import DailiesDateDropdown from '../ActionsBar/components/DateDropdown/DailiesDateDropdown';
import DateDropdown from '../ActionsBar/components/DateDropdown/DateDropdown';
import GanttVisualDateDropdown from '../ActionsBar/components/DateDropdown/GanttVisualDateDropdown';
import GanttColumnsDropdown from '../ActionsBar/components/GanttColumnsDropdown/GanttColumnsDropdown';
import TodayButton from '../ActionsBar/TodayButton';
import DailiesFilterDropdown from '../DailiesActionBar/DailiesFilterDropdown';
import {useFilterContext} from '../Filters/FilterProvider';
import {isFilterFieldsDirty, getDefaultFilterParamsByViewMode} from '../Filters/utils/functions';
import {usePrint} from '../Gantt/hooks/usePrint/usePrint';
import {getActivityName} from '../Gantt/utils/constants';
import {isPlaceholderTask} from '../Gantt/utils/gantt';
import {Toolbar} from '../Toolbar/Toolbar';

import s from './TasksActionsBar.module.scss';
import {TasksBulkActions} from './TasksBulkActions';
import TasksFilterDropdown from './TasksFilterDropdown';
import {useTasksBulkActions} from './useTasksBulkActions';

type ActionsBarProps = {
  className?: string;
  gantt?: GanttStatic;
  projectId: string;
  showTodayButton?: boolean;
};

const DATE_RANGE_FILTER_KEYS: (keyof TaskFilterQuery)[] = ['schedWeeks', 'schedEndFirst'];
const FILTER_EXCLUDE_KEYS: (keyof TaskFilterQuery)[] = [
  ...DATE_RANGE_FILTER_KEYS,
  'q',
  'showPastDue',
  'ganttCols',
  'documentId',
];

const TasksActionsBar = observer(function TasksActionsBar({gantt, projectId, showTodayButton}: ActionsBarProps) {
  const simpleLock = GanttSimpleLock.getInstance(gantt);
  const isTablet = useIsTablet();
  const [editingLocked, setEditingLocked] = useState(true);
  const dispatch = useRootDispatch();
  const routes = useLocalizedRoutes();
  const {t} = useTranslation('gantt');
  const {viewMode} = useFilterContext();
  const history = useHistory();
  const {queryParams} = useFilterContext();
  const {cantUndo, canRedo} = useUndo(gantt);
  const {indentSelectedTasks, outdentSelectedTasks} = useTasksActions(gantt);

  const {
    mixpanel: {events, track, trackWithAction},
  } = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = events.tasks;

  const [showTaskShareLink, setShowTaskShareLink] = useState(false);
  const [showCollaborationPopup, setShowCollaborationPopup] = useState(false);
  const [importCounter, setImportCounter] = useState(0);
  const [showImport, setShowImport] = useState(false);

  const {selected, bulkType, actions} = useTasksBulkActions({gantt, projectId});
  const uiStore = useInjectStore<UIStoreType>(IOC_TYPES.UIStore);
  const {isReadyForPrint, openPrintWindow} = usePrint(gantt, uiStore?.getDailiesPrintNodeRef);
  const [sideContentDirection, setSideContentDirection] = useState<'left' | 'right'>('left');

  const handleStaticItemsWrapped = (isWrapped: boolean) => {
    setSideContentDirection(isWrapped ? 'right' : 'left');
  };

  const hasSelectedActivities = (selected) =>
    Array.isArray(selected) &&
    selected
      .filter((id) => gantt.isTaskExists(id))
      .map((id) => gantt.getTask(id))
      .some((task) => task.object_type === TaskObjectType.activity || task.object_type === TaskObjectType.summary);

  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);

  const openNewTask = () => {
    trackWithAction(
      () => {
        const tasksStore = container.get<TasksStoreType>(IOC_TYPES.TasksStore);
        const topTasks = gantt.getChildren(gantt.config.root_id);
        const unfilteredTopTasks = tasksStore.tasks.filter((t) => t?.outline_sort_key?.length === OSK_SEGMENT_LENGTH);
        // If there is are onscreen toplevel tasks, add the new task immediately after the last one.
        // If some activities are hidden by the filtering, this may result in adding the task
        // in the middle of the unfiltered list of activities, which is desired.
        let neighbor = topTasks.reverse().find((tid) => !isPlaceholderTask(gantt, gantt.getTask(tid)));
        // If there are no onscreen toplevel tasks, add after the last unfiltered top level task
        if (!neighbor && unfilteredTopTasks.length > 0) {
          neighbor = unfilteredTopTasks[unfilteredTopTasks.length - 1].id;
        }
        if (neighbor) {
          gantt.createTask(
            {
              name: getActivityName(t),
              objectType: TaskObjectType.activity,
              relativeToId: neighbor,
              relativeDir: TaskRelativeDirection.After,
            },
            String(gantt.config.root_id),
          );
        } else {
          // No top level tasks exist, add at 0000000001
          gantt.createTask(
            {name: getActivityName(t), objectType: TaskObjectType.activity},
            String(gantt.config.root_id),
          );
        }
      },
      mixpanelEvents.toolbar.addNewTaskBtn,
      {viewMode},
    );
  };

  const undoOrRedo = (action: 'undo' | 'redo') => {
    return () => {
      if (action === 'undo') gantt.undo();
      if (action === 'redo') gantt.redo();
    };
  };

  const openProject = () => {
    history.push({
      pathname: generatePath(routes.project, {id: projectId}),
      state: {from: location.search},
    });
  };

  const openPrint = () => {
    trackWithAction(openPrintWindow, mixpanelEvents.toolbar.print);
  };

  const openTaskShare = () => {
    track(mixpanelEvents.toolbar.share);
    setShowTaskShareLink(true);
  };

  const onFinishTaskImport = (importedTasks: TaskImportObject['task'][]) => {
    const tasksStore = container.get<TasksStoreType>(IOC_TYPES.TasksStore);
    tasksStore.loadTasks();
    track(mixpanelEvents.import.exitBtn);
    setImportCounter((counter) => counter + 1);
    setShowImport(false);
    dispatch(getProject(projectId));

    if (importedTasks.length) {
      Gantt.list().forEach((instance) => {
        instance.needReload = true;
        instance.callEvent('afterTasksImport', []);
      });
    }
  };

  const dateFilterActive = isFilterFieldsDirty({
    queryParams,
    fields: DATE_RANGE_FILTER_KEYS,
    initialValues: getDefaultFilterParamsByViewMode(viewMode),
  });

  function indentWithLock() {
    simpleLock.run(async () => {
      await indentSelectedTasks();
    });
  }

  function outdentWithLock() {
    simpleLock.run(async () => {
      await outdentSelectedTasks();
    });
  }

  const filtersActive = isFilterFieldsDirty({
    queryParams,
    exclude: FILTER_EXCLUDE_KEYS,
    initialValues: getDefaultFilterParamsByViewMode(viewMode),
  });

  const baselineAction = useMemo(() => {
    return {
      name: 'baseline',
      text: 'Baseline',
      icon: 'baseline' as IconMapType,
      customButton: <BaselineDropdown gantt={gantt} viewportPosition="left" />,
      isDisabled: gantt.name !== GanttNames.gantt,
    };
  }, [gantt]);

  const detailsAction: ActionItem = {
    name: 'details',
    text: t('contextMenu.buttons.open', 'Details'),
    icon: 'info_outlined',
    actionType: 'single',
    onClick: () => {
      gantt.callEvent('onTaskOpen', [gantt.getSelectedTasks()[0]]);
    },
  };

  const riskReportAction: ActionItem = {
    name: 'status',
    text: t('contextMenu.buttons.daily_status', 'Daily Risk Report'),
    icon: 'warning',
    onClick: () => {
      trackWithAction(
        () => {
          const path = generatePath(routes.dailyRisk, {projectId});
          history.push({
            pathname: path,
          });
        },
        events.risk.reportButtonClicked,
        {viewMode},
      );
    },
    actionType: 'general',
  };

  const moreActions: ActionItem[] = useMemo(() => {
    return [
      {
        name: 'tasksColorMode',
        text: t('toolbar.actions.tasksColorMode', 'Color all by...'),
        customButton: <ColorTasksModeBtn gantt={gantt} />,
        isDisabled: !hasAnyAdminRole,
      },
      {
        name: 'import',
        text: t('toolbar.actions.import', 'Import file'),
        dataCy: 'btnImport',
        icon: 'import',
        hide: !hasAnyAdminRole,

        onClick: () => {
          setShowImport(true);
        },
      },
      {
        text: t('toolbar.actions.export', 'Export to CSV'),
        name: 'export',
        icon: 'export',
        actionType: 'general',
        customButton: <ExportDropdown />,
      },
      {
        text: t('toolbar.actions.settings', 'Settings'),
        name: 'settings',
        icon: 'settings',
        onClick: openProject,
        hide: !hasAnyAdminRole,
        actionType: 'general',
      },
    ];
  }, [gantt, hasAnyAdminRole, openProject, t]);

  const adminActionGroups: ActionGroup[] = useMemo(() => {
    return [
      {
        name: 'risk',
        actions: [riskReportAction],
      },
      {
        name: 'add',
        actions: [
          {
            name: 'add',
            text: t('contextMenu.buttons.add_activity', 'Add Activity'),
            icon: 'add_circle',
            onClick: openNewTask,
            actionType: 'general',
          },
        ],
      },
      {
        name: 'ur',
        actions: [
          {
            name: 'undo',
            text: t('toolbar.buttons.undo', 'Undo'),
            icon: 'undo_2',
            onClick: undoOrRedo('undo'),
            actionType: 'general',
            disabled: () => !cantUndo,
          },
          {
            name: 'redo',
            text: t('toolbar.buttons.redo', 'Redo'),
            icon: 'redo_2',
            onClick: undoOrRedo('redo'),
            actionType: 'general',
            disabled: () => !canRedo,
          },
        ],
      },
      {
        name: 'cp',
        actions: [
          {
            name: 'copy',
            text: t('contextMenu.buttons.copy', 'Copy'),
            icon: 'copy',
            onClick: actions.copyTasks,
            actionType: 'bulk',
          },
          {
            hide: true,
            name: 'paste',
            text: 'Paste',
            icon: 'paste_2',
            actionType: 'general',
          },
          {
            name: 'print',
            text: t('toolbar.buttons.print', 'Print'),
            icon: 'print',
            onClick: openPrint,
            disabled: () => !isReadyForPrint,
            hide: gantt.name === GanttNames.ganttVisual,
            actionType: 'general',
          },
        ],
      },
      {
        name: 'indent',
        actions: [
          {
            name: 'outdent',
            text: t('contextMenu.buttons.outdent', 'Outdent'),
            icon: 'outdent_2',
            onClick: () => outdentWithLock(),
            actionType: 'bulk',
          },
          {
            name: 'indent',
            text: t('contextMenu.buttons.indent', 'Indent'),
            icon: 'indent_2',
            onClick: () => indentWithLock(),
            actionType: 'bulk',
          },
        ],
      },
      {
        name: 'extra',
        actions: [
          detailsAction,
          {
            text: t('contextMenu.buttons.status', 'Status'),
            name: 'status',
            icon: 'status_new',
            onClick: actions.setStatus,
            actionType: 'bulk',
            disabled: (selected) => !hasSelectedActivities(selected),
          },
          {
            name: 'color',
            text: t('contextMenu.buttons.abbr_and_color', 'Abbreviation and color'),
            icon: 'paint_bucket',
            actionType: 'bulk',
            customButton: <ColorDropdown gantt={gantt} projectId={projectId} />,
          },
          {
            name: 'delete',
            text: t('contextMenu.buttons.bulk_delete', 'Bulk Delete'),
            icon: 'remove_from_trash',
            color: 'actionWarning',
            onClick: () => {
              trackWithAction(() => {
                actions.deleteSelectedTasks(projectId);
              }, mixpanelEvents.toolbar.bulkActions.delete);
            },
            actionType: 'bulk',
          },
        ],
      },
      {
        name: 'share',
        actions: [
          {
            name: 'share',
            text: t('contextMenu.buttons.share', 'Share'),
            icon: 'share_2',
            actionType: 'single',
            onClick: openTaskShare,
          },
          baselineAction,
        ],
      },
      {
        name: 'bulk',
        responsive: true,
        actions: [
          {
            text: t('contextMenu.buttons.subcontractor_small', 'Orgs'),
            name: 'orgs',
            size: 's',
            iconSize: 25,
            icon: 'company',
            onClick: actions.setSubcontractor,
            actionType: 'bulk',
            disabled: (selected) => !hasSelectedActivities(selected),
          },
          {
            text: t('contextMenu.buttons.assign', 'Assign'),
            name: 'assign',
            icon: 'user-add',
            onClick: actions.setAssignee,
            actionType: 'bulk',
            disabled: (selected) => !hasSelectedActivities(selected),
          },
          {
            text: t('contextMenu.buttons.watcher', 'Watcher'),
            name: 'watcher',
            icon: 'invisible',
            onClick: actions.setWatchers,
            actionType: 'bulk',
            disabled: (selected) => !hasSelectedActivities(selected),
          },
          {
            text: t('contextMenu.buttons.location', 'Location'),
            name: 'location',
            icon: 'poi_outlined',
            onClick: actions.setLocation,
            actionType: 'bulk',
            disabled: (selected) => !hasSelectedActivities(selected),
          },
          {
            text: t('contextMenu.buttons.type', 'Type'),
            name: 'type',
            icon: 'blend_tool',
            onClick: actions.setType,
            actionType: 'bulk',
            disabled: (selected) => !hasSelectedActivities(selected),
          },
        ],
        moreActions: moreActions,
      },
    ];
  }, [baselineAction, gantt, hasAnyAdminRole, moreActions, projectId, isReadyForPrint, cantUndo, canRedo]);

  const nonAdminActionGroups: ActionGroup[] = [
    {
      name: 'default',
      responsive: true,
      actions: [riskReportAction, detailsAction, baselineAction],
      moreActions: moreActions,
    },
  ];

  let chosenActionGroups = adminActionGroups;
  const isDailiesView = viewMode === TasksViewMode.dailies;

  if (!hasAnyAdminRole) {
    chosenActionGroups = nonAdminActionGroups;
  } else if (isDailiesView) {
    chosenActionGroups = [
      {
        name: 'print',
        actions: [
          {
            name: 'print',
            text: t('toolbar.buttons.print', 'Print'),
            icon: 'print',
            onClick: openPrint,
            disabled: () => !isReadyForPrint,
            hide: gantt.name === GanttNames.ganttVisual,
            actionType: 'general',
          },
        ],
      },
    ];
  }

  const whichCalendar = useCallback(() => {
    switch (viewMode) {
      case TasksViewMode.ganttVisual:
        return <GanttVisualDateDropdown gantt={gantt} active={dateFilterActive} key="gantt-visual-calendar" />;
      case TasksViewMode.dailies:
        return <DailiesDateDropdown key="dailies-calendar" />;
      default:
        return (
          <DateDropdown
            key="calendar"
            gantt={gantt}
            active={dateFilterActive}
            navigationType={viewMode === TasksViewMode.lookahead ? 'time' : 'scale'}
          />
        );
    }
  }, [dateFilterActive, gantt, viewMode]);

  const whichFilterDropdown = useMemo(
    () =>
      isDailiesView ? (
        <DailiesFilterDropdown key="dailiesFilter" />
      ) : (
        <TasksFilterDropdown
          key="filter"
          sideContentDirection={sideContentDirection}
          active={filtersActive}
          buttonIconOnly={true}
        />
      ),
    [filtersActive, isDailiesView, sideContentDirection],
  );

  useEffect(() => {
    if (isTablet) {
      gantt.config.readonly = editingLocked;
    }

    return () => {
      gantt.config.readonly = !hasAnyAdminRole;
    };
  }, [editingLocked, gantt.config, hasAnyAdminRole, isTablet]);

  const filterItems = useMemo(() => {
    const commonItems = [
      !isDailiesView ? (
        <GanttColumnsDropdown
          key="columns"
          gantt={gantt}
          button={<CtrlButton color="action" icon="column_view_outlined" iconOnly={true} />}
        >
          Columns
        </GanttColumnsDropdown>
      ) : null,

      !isDailiesView ? (
        <GanttSplitDropdown key="split" className={s.splitdropdown} onChange={(style) => gantt.setSplitStyle(style)} />
      ) : null,

      !isDailiesView && showTodayButton ? <TodayButton key="today" gantt={gantt} /> : null,

      whichCalendar(),

      whichFilterDropdown,

      hasAnyAdminRole && !isDailiesView ? (
        <CtrlButton
          key="projectCollab"
          size="s"
          className={s.collaborate}
          icon="collab"
          iconSize={20}
          color="second"
          onClick={() => setShowCollaborationPopup(true)}
        >
          {t('toolbar.actions.collaborate', 'Collaborate')}
        </CtrlButton>
      ) : null,

      isTablet && !isDailiesView ? (
        <CtrlButton
          color={editingLocked ? 'default' : 'actionWarning'}
          icon={editingLocked ? IconsMap.lock : IconsMap.unlock}
          iconSize={24}
          key="editLocked"
          onClick={() => {
            trackWithAction(() => setEditingLocked((prev) => !prev), events.tasks.toolbar.ipadLock(!editingLocked));
          }}
          size="xs"
          type="button"
        >
          {editingLocked ? t('toolbar.buttons.edit.locked') : t('toolbar.buttons.edit.unlocked')}
        </CtrlButton>
      ) : null,
    ];

    return commonItems.filter(Boolean);
  }, [
    isDailiesView,
    gantt,
    showTodayButton,
    whichCalendar,
    whichFilterDropdown,
    hasAnyAdminRole,
    t,
    isTablet,
    editingLocked,
    trackWithAction,
    events.tasks.toolbar,
  ]);

  const toolbarChildren = useMemo(
    () =>
      chosenActionGroups.map((group, idx) => {
        const index = chosenActionGroups.length > 1 ? idx : undefined;
        return (
          <Toolbar.Section index={index} key={group.name}>
            {group.actions.map((action) => (
              <Toolbar.Item key={action.name} selected={selected} {...action} />
            ))}
            {!!group.moreActions?.length && (
              <Toolbar.MoreActions canEdit={hasAnyAdminRole} actions={group.moreActions} selected={selected} />
            )}
          </Toolbar.Section>
        );
      }),
    [chosenActionGroups, selected, hasAnyAdminRole],
  );

  return (
    <>
      <Toolbar
        className={s.toolbar}
        onStaticItemsWrappedChange={handleStaticItemsWrapped}
        staticItems={filterItems}
        readonly={!hasAnyAdminRole}
      >
        {toolbarChildren}
      </Toolbar>
      <ShareTask
        projectId={projectId}
        viewMode={viewMode}
        taskId={selected[0]}
        visible={showTaskShareLink}
        onClose={() => setShowTaskShareLink(false)}
      />
      <TasksBulkActions gantt={gantt} bulkType={bulkType} projectId={projectId} closePopup={actions.closeBulkModal} />
      <TasksImportPopup
        defaultProjectId={projectId}
        key={importCounter}
        onClose={() => trackWithAction(() => setShowImport(false), mixpanelEvents.import.exitBtn)}
        onFinish={onFinishTaskImport}
        visible={showImport}
        viewMode={viewMode}
      />
      {showCollaborationPopup && <ProjectCollaboratePopup visible onClose={() => setShowCollaborationPopup(false)} />}
    </>
  );
});

export default TasksActionsBar;
