import {BaselineSnapshot} from './task/task';
import {Worker} from './worker';

export type ProjectModelBase = {
  companyId?: string;
  id?: string;
  name?: string;
  locations?: string[];
  shortname?: string;
  timeCreated?: string;
  timeUpdated?: string;
  taskCount?: number;
  country?: string;
  state?: string;
  city?: string;
  timezone?: string;
  importSettings?: string;
  defaultTaskStart?: string;
  defaultTaskEnd?: string;
  calendar?: ProjectCalendar;
  baselines?: BaselineSnapshot[];
  architectCompanyGroupMappingId: string;
  engineerCompanyGroupMappingId: string;
  ownerCompanyGroupMappingId: string;
  subcontractorCompanyGroupMappingId: string;
};
export type ProjectModel = Omit<ProjectModelBase, 'defaultTaskStart' | 'defaultTaskEnd'> & {
  responsibleParties?: Array<string>;
  defaultTaskStart: Date;
  defaultTaskEnd: Date;
  customFieldDef?: ProjectCustomFieldDef[];
};

export enum ProjectCustomFieldType {
  string = 'string',
  number = 'number',
  date = 'date',
  multiselect = 'multiselect',
  select = 'select',
}

export type ProjectCustomFieldDef = {
  fieldName: string;
  fieldType: ProjectCustomFieldType;
  internalFieldName?: string;
  fieldData?: string;
};

export enum WorkDaysEnum {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export type ProjectCalendarException = {
  date: string;
  working: boolean;
};

export type ProjectCalendar = {
  exceptions: ProjectCalendarException[];
  workDays: WorkDaysEnum[];
};

export type ProjectWorkflow = ProjectWorkflowItem[];
type ProjectWorkflowItem = {
  assign: 'TRUE' | 'FALSE';
  comments: 'TRUE' | 'FALSE';
  fromStatus: string;
  owner_only: 'TRUE' | 'FALSE';
  toStatus: string;
  verb: string;
};

export type ActivityIdRowNumber = {
  id: string; // task_id
  rownum: number;
};

export type ActivitiesIdRowMap = Record<string | number, string | number>;
type ProjectCollabWorker = {
  email?: string;
  mobileNumber?: string;
  workerId?: string;
  isReadOnly?: boolean;
};

export type ProjectCollabRequest = {
  message: string;
  workers: ProjectCollabWorker[];
  viewFilters: string;
};

export type ProjectCollabInfo = {
  workers: Worker[];
} & Omit<ProjectCollabRequest, 'workers'>;
