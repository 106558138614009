import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import {Field, FieldProps, Formik} from 'formik';
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import {toast} from 'react-toastify';

import ProjectsApi from 'api/projects';
import CoreSelectField from 'shared/components/CoreForm/Select/Select';
import Loader from 'shared/components/Loader';
import Popup from 'shared/components/Popup/Popup';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {toTitleCase} from 'shared/helpers/common';
import {getProjectCustomField} from 'shared/helpers/project';
import {useProject} from 'shared/hooks/useProject';
import {ProjectCustomFieldDef, ProjectCustomFieldType} from 'shared/models/project';
import {useRootDispatch} from 'store';
import {getFieldTypesOptions, validationSchema} from './utils';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import {projectActions} from 'store/projects';

import s from './ProjectCustomColumnPopup.module.scss';

type Props = {
  visible: boolean;
  onClose: () => void;
  onAfterCreate: (field: ProjectCustomFieldDef) => void;
  projectId: string;
  internalFieldName: string | null;
};

type CustomFieldCreationField = {
  fieldName: string;
  fieldType: ProjectCustomFieldType;
  fieldData: string;
};

const VALUES_DELIMITER = '\n';

const ProjectCustomColumnPopup: FC<Props> = ({
  visible,
  onClose,
  onAfterCreate,
  projectId,
  internalFieldName,
}: Props) => {
  const {project} = useProject(projectId);
  const {t} = useTranslation('project');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useRootDispatch();
  const isNewField = !internalFieldName;

  const submit = async (values: CustomFieldCreationField) => {
    setIsLoading(true);
    let toastMessage = t('custom_field_popup.toast.success.action.created', 'New column created successfully.');
    try {
      if (!isNewField) {
        await updateFieldData(values.fieldName, values.fieldData);
        toastMessage = t('custom_field_popup.toast.success.action.updated', 'Custom column updated successfully.');
      } else {
        const created = await createField(values);
        onAfterCreate(created);
      }
      toast.success(toastMessage);
      onClose();
    } catch (e) {
      Sentry.captureException(e);
      if (isAxiosError(e)) toast.error(extractAxiosError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const updateFieldData = async (updatedFieldName, updatedFieldData) => {
    const model = {
      fieldData: JSON.stringify(updatedFieldData?.trim().split(VALUES_DELIMITER)),
      fieldName: toTitleCase(updatedFieldName),
    };
    await ProjectsApi.updateCustomFieldDef(projectId, {
      internalFieldName,
      ...model,
    });
    dispatch(
      projectActions.updateCustomColumn({
        projectId: project.id,
        internalFieldName,
        ...model,
      }),
    );
  };

  const createField = async (values: CustomFieldCreationField): Promise<ProjectCustomFieldDef> => {
    const {fieldName, fieldData, fieldType} = values;
    const supportFieldData = [ProjectCustomFieldType.select, ProjectCustomFieldType.multiselect].includes(fieldType);
    const createdField = await ProjectsApi.createCustomFieldDef(projectId, {
      fieldType,
      fieldData: supportFieldData ? JSON.stringify(fieldData?.trim().split(VALUES_DELIMITER)) : undefined,
      fieldName: toTitleCase(fieldName.trim()),
    });
    dispatch(
      projectActions.updateProject({
        id: project.id,
        changes: {customFieldDef: project.customFieldDef.concat([createdField])},
      }),
    );

    return createdField;
  };

  const getInitialValues = () => {
    const initialValues = {fieldName: '', fieldType: ProjectCustomFieldType.string, fieldData: ''};
    if (!internalFieldName) {
      return initialValues;
    }
    const column = getProjectCustomField(project, internalFieldName);
    if (!column) {
      return initialValues;
    }
    const {fieldType, fieldData} = column;
    const parsedFieldData = fieldData ? JSON.parse(fieldData) : '';
    return {
      fieldType,
      fieldName: column.fieldName,
      fieldData: parsedFieldData.length ? parsedFieldData.join(VALUES_DELIMITER) : undefined,
    };
  };

  return (
    <Popup visible={visible} className={s.creationPopup} onClose={onClose} closeOnOutsideClick={false}>
      <Formik<CustomFieldCreationField>
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {({setFieldValue, handleSubmit, values}) => {
          return (
            <>
              <Popup.Body>
                <form id="createCustomFieldForm" className={s.form} onSubmit={handleSubmit}>
                  {isLoading && <Loader />}
                  <h1 className={cn(s.form__title, s.form__title_block)}>
                    {isNewField
                      ? t('custom_field_popup.title.create_new', 'Custom column creation')
                      : t('custom_field_popup.title.edit_existed', 'Edit custom column')}
                  </h1>
                  <FormControl.Formik
                    className={s.form__formControl}
                    name="fieldName"
                    label={t('custom_field_popup.form_field.name.label', 'Name')}
                  >
                    {({field}: FieldProps) => (
                      <Field
                        className="ctrl-textfield"
                        name="fieldName"
                        placeholder={t('custom_field_popup.form_field.name.placeholder', 'Type field name...')}
                        {...field}
                      />
                    )}
                  </FormControl.Formik>
                  <FormControl.Formik
                    name="fieldType"
                    label={t('custom_field_popup.form_field.type.label', 'Column Type')}
                    className={s.form__formControl}
                  >
                    {({field}: FieldProps) => (
                      <CoreSelectField
                        isDisabled={!isNewField}
                        {...field}
                        options={getFieldTypesOptions(t)}
                        onChange={(value) => {
                          setFieldValue(field.name, value);
                        }}
                      />
                    )}
                  </FormControl.Formik>
                  {[ProjectCustomFieldType.select, ProjectCustomFieldType.multiselect].includes(values.fieldType) && (
                    <FormControl.Formik
                      name="fieldData"
                      label={t('custom_field_popup.form_field.values.label', 'Values')}
                      className={s.form__formControl}
                    >
                      {({field}: FieldProps) => (
                        <TextareaAutosize
                          className="ctrl-textfield"
                          maxRows={6}
                          minRows={6}
                          {...field}
                          placeholder={t('custom_field_popup.form_field.values.placeholder', 'Values')}
                        />
                      )}
                    </FormControl.Formik>
                  )}
                </form>
              </Popup.Body>
              <Popup.Footer>
                <CtrlButton color="second" onClick={onClose} type="button">
                  {t('custom_field_popup.button.cancel', 'Cancel')}
                </CtrlButton>
                <CtrlButton className={s.form__submitButton} type="submit" form="createCustomFieldForm">
                  {t('custom_field_popup.button.submit', 'Submit')}
                </CtrlButton>
              </Popup.Footer>
            </>
          );
        }}
      </Formik>
    </Popup>
  );
};
export default ProjectCustomColumnPopup;
