import './styles.scss';

import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import Loader from 'shared/components/Loader';
import {formatDate} from 'shared/helpers/dates';
import {IOC_TYPES} from 'shared/models/ioc';
import {useInjectStore} from 'shared/providers/injection';
import {UIStoreType} from 'shared/stores/UIStore';

import {DailiesGeneralComments} from './DailiesGeneralComments/DailiesGeneralComments';
import {DailiesReportHeader} from './DailiesReportHeader';
import {DailiesReportIssues} from './DailiesReportIssues';
import {DailyTasksWithNoReports} from './DailyTasksWithNoReports/DailyTasksWithNoReports';
import {DailyTasksWithReports} from './DailyTaskWithReport/DailyTaskWithReport';
import {useDailiesReports} from './hooks/useDailiesReports';
import {useFetchDailiesIssues} from './hooks/useFetchDailiesIssues';

export function DailiesView() {
  const {t} = useTranslation('dailies');
  const {queryParams} = useFilterContext();
  const componentRef = useRef<HTMLDivElement>(null);
  const uiStore = useInjectStore<UIStoreType>(IOC_TYPES.UIStore);
  const selectedDay = formatDate(queryParams.schedEndFirst ?? '', 'LL');
  const hasSearchQuery = queryParams?.q;
  const {hasTasksListForCurrentDay, isLoading: areActivitiesLoading} = useDailiesReports();
  const {isLoading: areIssuesLoading} = useFetchDailiesIssues();

  useEffect(() => {
    uiStore.setDailiesPrintNodeRef(componentRef);
    return () => {
      uiStore.setDailiesPrintNodeRef({current: null});
    };
  }, [componentRef, uiStore]);

  if (areActivitiesLoading || areIssuesLoading) {
    return (
      <section className="dailies-container">
        <div ref={componentRef} className="daily-report">
          <Loader />
        </div>
      </section>
    );
  }

  if (!hasTasksListForCurrentDay) {
    return (
      <section className="dailies-container">
        <div ref={componentRef} className="daily-report">
          <DailiesReportHeader selectedDay={selectedDay} />
          <DailiesReportIssues />
          <DailiesGeneralComments />
          <div className="no-data-message">
            <h2>
              {hasSearchQuery && !hasTasksListForCurrentDay
                ? t('dailies_report.no_search_results')
                : t('dailies_report.no_data_reported')}
            </h2>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="dailies-container">
      <div ref={componentRef} className="daily-report">
        <DailiesReportHeader selectedDay={selectedDay} />

        <DailiesReportIssues />

        <DailiesGeneralComments />

        <DailyTasksWithReports />

        <DailyTasksWithNoReports />
      </div>
    </section>
  );
}
