import {useCallback, useMemo, useEffect} from 'react';
import {useQuery, useQueryClient} from 'react-query';

import ProjectsApi from 'api/projects';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {WakeCapZones} from 'shared/models/task/task';

const removeDuplicateZones = (zones: WakeCapZones[]) =>
  Array.from(new Map(zones.map((zone) => [`${zone.zoneName}-${zone.spaceId}`, zone])).values());

const useZones = (projectId: string) => {
  const queryClient = useQueryClient();

  const fetchZones = useCallback(async () => {
    const zones = await ProjectsApi.getWakeCapZones(projectId);
    return removeDuplicateZones(zones);
  }, [projectId]);

  const ZONES_CACHE_KEY = useMemo(() => [QUERY_CACHE_KEYS.zones, projectId], [projectId]);

  useEffect(() => {
    if (!queryClient.getQueryData<WakeCapZones[]>(ZONES_CACHE_KEY)) {
      queryClient.prefetchQuery(ZONES_CACHE_KEY, fetchZones);
    }
  }, [queryClient, ZONES_CACHE_KEY, fetchZones]);

  const query = useQuery({
    queryKey: QUERY_CACHE_KEYS.zones(projectId),
    queryFn: fetchZones,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
    initialData: () => {
      const cachedData = queryClient.getQueryData<WakeCapZones[]>(ZONES_CACHE_KEY);
      return cachedData ? removeDuplicateZones(cachedData) : undefined;
    },
  });

  return query;
};

export default useZones;
