import {GANTT_COLUMNS_NAMES} from '../../utils/constants';

export const DISABLED_COLUMN_TEMPLATES = [GANTT_COLUMNS_NAMES.name];
export const DATE_COLUMNS_NAMES = [
  GANTT_COLUMNS_NAMES.actualStart,
  GANTT_COLUMNS_NAMES.actualEnd,
  GANTT_COLUMNS_NAMES.endDate,
  GANTT_COLUMNS_NAMES.startDate,
  GANTT_COLUMNS_NAMES.baselineStart,
  GANTT_COLUMNS_NAMES.baselineEnd,
  GANTT_COLUMNS_NAMES.doneDate,
  GANTT_COLUMNS_NAMES.inprogressDate,
];
export const PRINT_CSS_PATHS = [
  'stylesheets/common.css',
  'stylesheets/landings.min.css',
  'stylesheets/dhtmlxgantt.css',
  'stylesheets/print-lookahead.css',
  'stylesheets/print-lookahead-custom.css',
];
