import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {ProjectCustomFieldDef} from 'shared/models/project';
import {TaskDetailsModelDTO, TaskModelDTO} from 'shared/models/task/task';

export type CurrencyObject = {
  amount: number;
  currency: Dinero.Currency;
};

export const TaskImportClientMappingKeys = [
  'uniqueId',
  'name',
  'schedStartDate',
  'schedEndDate',
  'subcontractor',
  'assignedNames',
  'location',
  'description',
  'archived',
  'shouldImport',
  'outlineCode',
  'cost',
  'type',
  'predecessors',
  'phaseCode',
  'actualStart',
  'actualEnd',
  'projectedLabor',
  'estLaborHours',
  'costCode',
  'customCode',
  'csiCode',
  'completionTarget',
  'completionUnit',
] as const;

export type StaticTaskImportClientMappingKeys = (typeof TaskImportClientMappingKeys)[number];

export type TaskImportClientMapping = Record<StaticTaskImportClientMappingKeys, string>;

export type TaskImportNameResolutions = {
  fullName?: string;
  name?: string;
  workerId?: string;
};

export type TaskImportProjectCustomFieldDef = ProjectCustomFieldDef & {mappedTo: string};

export type TasksImportConfig = {
  customFields: TaskImportProjectCustomFieldDef[];
  dateFormat: string;
  defaultProject: string;
  importWithHierarchy?: boolean;
  mapping: TaskImportClientMapping;
};

type TaskImportKeys = keyof TasksImportConfig['mapping'];
export type TaskImportTotals = {all: number; new: number; error: number; edited: number; unChanged?: number};

export type TaskImportFieldPreview = Omit<TaskImportField, 'key'> & {
  key: TaskImportClientMapping &
    keyof {
      uniqueId?: string;
      assignedNames?: string;
      subcontractor?: string;
    };
};

export type TaskImportField = {
  label: string;
  columnTitle?: string;
  required?: boolean;
  key?: TaskImportKeys;
  accessor?: (
    value: TaskImportObject['task'],
    model: TaskImportObject['nameResolutions'],
    config?: TasksImportConfig,
  ) => unknown;
  comparer?: (importData: TaskImportObject['task'], source: TaskDetailsModelDTO, config?: TasksImportConfig) => boolean;
  setter?: (item: TaskImportObject['task'], value: unknown, config?: TasksImportConfig) => unknown;
  options?: CoreOptionType[];
};

type TaskImportMainError = {
  fatal: boolean;
  code: string;
};

type TaskImportMinorError = {
  field: string;
  messages: string[];
  fatal?: boolean;
};

export type TaskImportObject = {
  id: string;
  errors: TaskImportMinorError[];
  updatedFields: string[];
  nameResolutions: TaskImportNameResolutions[];
  task: TaskModelDTO & {
    assignedNames: string;
    shouldImport: string;
    outlineCode?: string;
    type?: string;
    subcontractor: string;
    responsibleOrgId: string;
    predecessors: string;
    cost: CurrencyObject;
  };
  status: 'created' | 'failed' | 'updated' | 'unchanged';
};

export type AsyncCommitResult<R> = {
  result: R;
  resultFileUrl: string;
  progressPercent: number;
  status: 'finished' | 'failed' | 'queued' | 'running';
};

type TaskImportPrepResult = {
  hasHierarchy: boolean;
  errors?: TaskImportMainError[];
  error?: string;
  headers: string[];
  samples: unknown[];
};

export type TaskImportResult = {
  addedCount: number;
  updatedCount: number;
  unchangedCount: number;
  taskResponses: TaskImportObject[];
  errors: TaskImportMainError[];
  error?: string;
  export?: string;
};

export type DocumentTransferStatusResponse = {
  result?: {
    resourceId: string;
  };
  progressPercent: number;
  status: 'finished' | 'failed' | 'queued' | 'running';
};

export type TaskImportResponse = AsyncCommitResult<TaskImportResult>;
export type TaskImportPrepResponse = AsyncCommitResult<TaskImportPrepResult>;
