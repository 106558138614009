import ProjectsApi from 'api/projects';
import {TaskImportProjectCustomFieldDef} from 'shared/components/TasksImport/utils/types';
import {toTitleCase} from 'shared/helpers/common';
import {ProjectCustomFieldType, ProjectCustomFieldDef} from 'shared/models/project';

// Handle API calls for custom fields
export const processCustomFieldsApi = async (
  customFields: TaskImportProjectCustomFieldDef[],
  projectId: string,
  existingCustomFields: ProjectCustomFieldDef[],
) => {
  const existingFieldsMap = new Map(existingCustomFields.map((field) => [field.internalFieldName, field]));

  const customFieldDefPromise = customFields.reduce(
    (acc, field) => {
      const normalizedFieldName = field.fieldName.trim().replace(/\s+/g, ' ');
      const supportFieldData = [ProjectCustomFieldType.select, ProjectCustomFieldType.multiselect].includes(
        field.fieldType,
      );

      const params = {
        fieldType: field.fieldType,
        fieldName: toTitleCase(normalizedFieldName),
        fieldData: supportFieldData ? JSON.stringify(field.fieldData?.trim().split('\n')) : '',
        internalFieldName: field.internalFieldName ?? undefined,
      };

      if (!field.internalFieldName) {
        acc.created.push(ProjectsApi.createCustomFieldDef(projectId, params));
      } else {
        const existingField = existingFieldsMap.get(field.internalFieldName);
        if (existingField && existingField.fieldName !== params.fieldName) {
          acc.updated.push(
            ProjectsApi.updateCustomFieldDef(projectId, params).then((okay) => {
              if (okay) {
                return params;
              }
            }),
          );
        } else {
          acc.updated.push(Promise.resolve(params));
        }
      }

      return acc;
    },
    {
      updated: [] as Promise<ProjectCustomFieldDef>[],
      created: [] as Promise<ProjectCustomFieldDef>[],
    },
  );

  return {
    updated: await Promise.all(customFieldDefPromise.updated),
    created: await Promise.all(customFieldDefPromise.created),
  };
};

// Handle import settings updates
export const updateImportSettings = (
  existingSettings: string,
  customFields: TaskImportProjectCustomFieldDef[],
  updated: ProjectCustomFieldDef[],
  created: ProjectCustomFieldDef[],
) => {
  const settings = JSON.parse(existingSettings || '{"mapping":{}, "customFieldsMapping":{}}');

  const customFieldsMapping = Object.fromEntries(
    customFields.map((field) => {
      const normalizedFieldName = toTitleCase(field.fieldName.trim().replace(/\s+/g, ' '));
      const matchingField = [...updated, ...created].find((cfRes) => cfRes.fieldName === normalizedFieldName);
      return [matchingField.internalFieldName, field.mappedTo];
    }),
  );

  return JSON.stringify({
    ...settings,
    customFieldsMapping,
  });
};
