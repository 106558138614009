import {FC, useCallback, useEffect, useMemo, useRef} from 'react';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import Popup from 'shared/components/Popup/Popup';
import {ProSubscription, FormattedProPlan, ProSubscriptionTypes} from 'shared/models/checkout';
import {CompanyModel} from 'shared/models/company';

import CancelReason from './CancelReason';
import {CheckoutContextProvider} from './CheckoutContext';
import CheckoutResult from './CheckoutResult';
import CheckoutStripe from './CheckoutStripe';
import PickPlan from './PickPlan';
import {subscriptionActionsCreators, SubscriptionStep, useSubscriptionState} from './state';
import SubscriptionPreview from './SubscriptionPreview';
import {CheckoutNavigationProps} from './utils/types';

type SubscriptionPreviewProps = {
  company: CompanyModel;
  subscription: ProSubscription;
  plan?: FormattedProPlan;
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  onSubscriptionCanceled: () => void;
  onSubscriptionUpdated: (cur: ProSubscription, prev: ProSubscription) => void;
};

interface StepComponent {
  text: string;
  step: SubscriptionStep[];
  Component: React.ComponentType<CheckoutNavigationProps>;
}

const SubscriptionModal: FC<SubscriptionPreviewProps> = ({
  visible,
  onVisibleChange,
  company,
  subscription,
  plan,
  onSubscriptionCanceled,
  onSubscriptionUpdated,
}) => {
  const {t} = useTranslation('company');
  const [state, dispatch] = useSubscriptionState();
  const checkoutPopup = useRef<Window>();

  const isInitialStep = state.status === SubscriptionStep.initial;

  useEffect(() => {
    dispatch({type: 'SET_SUBSCRIPTION', payload: subscription});
  }, [subscription, dispatch]);

  useEffect(() => {
    dispatch({type: 'SET_PRO_PLANS', payload: plan});
  }, [plan, dispatch]);

  useEffect(() => {
    if (isInitialStep && !state.subscription) {
      dispatch(subscriptionActionsCreators.setStep(SubscriptionStep.pickPlan));
      return;
    }
    if (isInitialStep && state.subscription?.type === ProSubscriptionTypes.FOREMAN_FREE) {
      dispatch(subscriptionActionsCreators.setStep(SubscriptionStep.upgrading));
      return;
    }
    if (state.status === SubscriptionStep.pickPlan && state.subscription?.type === ProSubscriptionTypes.PRO) {
      dispatch(subscriptionActionsCreators.setStep(SubscriptionStep.initial));
    }
  }, [state, dispatch]);

  const stepsComponents: Array<StepComponent> = useMemo(
    () => [
      {
        text: t('subscription.upgrade_plan.steps.1.title', '1 Pick a plan'),
        Component: PickPlan,
        step: [SubscriptionStep.pickPlan, SubscriptionStep.upgrading],
      },
      {
        text: t('subscription.upgrade_plan.steps.2.title', '2 Secured Checkout'),
        Component: CheckoutStripe,
        step: [SubscriptionStep.checkout],
      },
      {
        text: t('subscription.upgrade_plan.steps.3.title', `3 Receipt`),
        Component: CheckoutResult,
        step: [SubscriptionStep.finished],
      },
    ],
    [t],
  );

  const handleModalClose = () => {
    if (state.status === SubscriptionStep.checkout && checkoutPopup.current && !checkoutPopup.current?.closed) {
      toast.info(t('subscription.notifications.close_checkout', 'Finish or cancel checkout process '), {
        autoClose: 500,
      });
      checkoutPopup.current.focus();
    } else {
      onVisibleChange(false);
      if (state.status === SubscriptionStep.finished && state.subscription) {
        onSubscriptionUpdated(state.subscription, state.prevSubscription);
      }
      dispatch(subscriptionActionsCreators.reset());
    }
  };

  const onCanceled = () => {
    handleModalClose();
    onSubscriptionCanceled();
  };

  const setCheckoutPopup = useCallback((ref: Window) => {
    checkoutPopup.current = ref;
  }, []);

  const CurrentStepComponent = stepsComponents.find((component) => component.step.includes(state.status))?.Component;
  const getStepperBody = () => {
    return (
      <>
        <Popup.Header>
          <div className="tabs-nav popup__nav">
            {stepsComponents.map((component, i) => (
              <button
                key={i}
                className={`tabs-nav__button${component.step.includes(state.status) ? ' is-active' : ''}`}
                type="button"
              >
                {component.text}
              </button>
            ))}
          </div>
        </Popup.Header>
        {CurrentStepComponent ? <CurrentStepComponent close={() => handleModalClose()} /> : null}
      </>
    );
  };

  const getPreview = () => <SubscriptionPreview />;
  return (
    <CheckoutContextProvider
      value={{
        state,
        dispatch,
        onCanceled,
        childWindow: checkoutPopup.current,
        setCheckoutPopup,
        company,
      }}
    >
      <Popup visible={visible} onClose={handleModalClose} className="popup__item--size-m" closeOnOutsideClick={false}>
        {CurrentStepComponent ? (
          getStepperBody()
        ) : state.status === SubscriptionStep.canceling ? (
          <CancelReason />
        ) : (
          getPreview()
        )}
      </Popup>
    </CheckoutContextProvider>
  );
};
export default SubscriptionModal;
