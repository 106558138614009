import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import Icon from 'shared/components/Icon';
import Popup from 'shared/components/Popup/Popup';
import {safeFormatDate} from 'shared/helpers/dates';
import {useFormattedProPlanTiers} from 'shared/hooks/useFormattedProPlanTiers';

import {useSubscriptionContext} from '../CheckoutContext';
import {findCurrentPlan} from '../state';
import {parseNumberAsPrice} from '../utils/helpers';
import {CheckoutNavigationProps} from '../utils/types';

type CheckoutResultProps = CheckoutNavigationProps;

const CheckoutResult: FC<CheckoutResultProps> = ({close}) => {
  const {t} = useTranslation('company');
  const {
    state: {subscription, prevSubscription, proPlan},
    company,
  } = useSubscriptionContext();

  const formattedProPlanTiers = useFormattedProPlanTiers(proPlan);

  const prevPlan = useMemo(() => {
    return prevSubscription ? findCurrentPlan(prevSubscription, formattedProPlanTiers) : null;
  }, [prevSubscription, formattedProPlanTiers]);

  const invoiceTotal = parseNumberAsPrice(subscription.stripeSubscriptionInfo.amount / 100);

  return (
    <>
      <Popup.Body>
        <div className="form-plan">
          <h2 className="form-plan__title">{t('subscription.upgrade_plan.success_screen.title', 'Success!')}</h2>
          <div className="form-plan__description">
            <p>
              {prevSubscription
                ? t('subscription.upgrade_plan.success_screen.description.upgraded', "You've upgraded your plan")
                : t(
                    'subscription.upgrade_plan.success_screen.description.new_plan',
                    'Your transaction has been approved',
                  )}
            </p>
          </div>
          <section className="plan-info form-plan__summary">
            <div className="plan-info__container">
              <img className="plan-info__icon" src="/images/other/complete-ultra.svg" />
              <h3 className="plan-info__title">Crews by Core Pro</h3>
              <div className="lava-list">
                <div className="lava lava-list__item">
                  <div className="lava__label">
                    {t('subscription.upgrade_plan.success_screen.checkout_info.recipient.title', 'Recipient')}
                  </div>
                  <div className="lava__value">
                    {t(
                      'subscription.upgrade_plan.success_screen.checkout_info.recipient.description',
                      'Journey Builders LLC',
                    )}
                  </div>
                </div>
                <div className="lava lava-list__item">
                  <div className="lava__label">
                    {prevPlan
                      ? t(
                          'subscription.upgrade_plan.success_screen.checkout_info.operation_type.change',
                          'Licenses change',
                        )
                      : t(
                          'subscription.upgrade_plan.success_screen.checkout_info.operation_type.new',
                          'Current licenses',
                        )}
                  </div>
                  {!prevSubscription?.stripeSubscriptionInfo ? (
                    <div className="lava__value">
                      <Icon name="group_equal" colorFill className="lava__icon" />
                      {subscription.stripeSubscriptionInfo.quantity}
                    </div>
                  ) : (
                    <>
                      <div className="lava__value lava__value--old">
                        <Icon name="group_equal" colorFill className="lava__icon" />
                        {prevSubscription.stripeSubscriptionInfo.quantity}
                      </div>
                      <Icon name="arrow_forward" colorFill className="lava__icon-old" />
                      <div className="lava__value lava__value--new">
                        <Icon name="group_equal" colorFill className="lava__icon" />
                        {subscription.stripeSubscriptionInfo.quantity}
                      </div>
                    </>
                  )}
                </div>

                <div className="lava lava-list__item">
                  <div className="lava__label">
                    {t('subscription.upgrade_plan.success_screen.checkout_info.workers_qty', 'Number of workers')}
                  </div>
                  <div className="lava__value lava__value--users">
                    <Icon className="lava__icon" colorFill name="group_equal" />
                    {company.currentSeatCount}
                  </div>
                </div>
                <div className="lava lava-list__item">
                  <div className="lava__label">
                    {t('subscription.upgrade_plan.success_screen.checkout_info.invoice_total', 'Invoice total')}
                  </div>
                  <div className="lava__value">{invoiceTotal}</div>
                </div>
                <div className="lava lava-list__item">
                  <div className="lava__label">
                    {t('subscription.upgrade_plan.success_screen.checkout_info.next_invoice_date', 'Next invoice on')}
                  </div>
                  <div className="lava__value">
                    {safeFormatDate(subscription?.stripeSubscriptionInfo?.nextInvoice, 'll')}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Popup.Body>
      <Popup.Footer>
        <button className="ctrl-btn popup__button" type="button" onClick={close}>
          <span className="ctrl-btn__text">
            {t('subscription.upgrade_plan.success_screen.actions.finish', 'Finish')}
          </span>
        </button>
      </Popup.Footer>
    </>
  );
};
export default CheckoutResult;
