import dayjs from 'dayjs';

import {useFetchFeedback} from 'shared/hooks/useFetchFeedback';
import {useProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {FeedbackGroupedByDate} from 'shared/models/task/comments';

import {groupFeedback} from './utils/groupFeedback';

type Props = {
  projectId?: string;
  taskId: string;
  issueId?: string;
};

export function useCommentsFeed(params: Props) {
  const {projectId, taskId, issueId} = params;
  const queryResult = useFetchFeedback({projectId, taskId, issueId});
  const {projectWorkers} = useProjectWorkers();
  let groupedFeedback: FeedbackGroupedByDate;

  function findReportSubmitterById(workerId: string) {
    return projectWorkers?.find((worker) => worker?.workerId === workerId);
  }

  if (queryResult.isSuccess && queryResult.data) {
    groupedFeedback = groupFeedback({feedbackList: queryResult.data, findReportSubmitterById});
  }

  const structuredFeedback = Array.from(groupedFeedback?.values() ?? []).sort(
    (a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf(),
  );

  return {
    comments: structuredFeedback,
    hasNoComments: queryResult.isSuccess && groupedFeedback?.size === 0,
    ...queryResult,
  };
}
