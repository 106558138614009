import {TFunction} from 'react-i18next';
import {object, string} from 'yup';

import {SelectWorkerType} from './ProjectCollabPopup';

export function isNewAddedWorker({value}: SelectWorkerType) {
  return !isNaN(parseInt(value.workerFull.id));
}

export const validationSchema = (t: TFunction) =>
  object().shape({
    email: string().email((inst) =>
      t('collaboration:popup.validation.email', '{{email}} is not a valid email address', {email: inst.value}),
    ),
    phoneNumber: string().matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, {
      message: (inst) =>
        t('collaboration:popup.validation.phoneNumber', '{{phoneNumber}} is not a valid phone number', {
          phoneNumber: inst.value,
        }),
    }),
  });

// Yup's built in Yup.string().email() will not check for consecutive dots like email@email..com which allows for invalid email formats
// https://github.com/jquense/yup/issues/507
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(inputValue: string) {
  try {
    string().matches(EMAIL_REGEX).validateSync(inputValue);
    return true;
  } catch (e) {
    return false;
  }
}

export function isValidPhone(inputValue: string): boolean {
  if (!inputValue) {
    return false;
  }
  // Remove non-numeric characters
  const sanitizedValue = inputValue.replace(/[^\d+]/g, '');

  // Check for a valid phone format
  return /^\+?[1-9]\d{9,14}$/.test(sanitizedValue);
}
