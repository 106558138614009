import {GanttStatic} from 'dhtmlx-gantt';

import {useInlineSelectController} from 'modules/Tasks/components/Gantt/components/Editors/useInlineSelectController';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import Select from 'shared/components/CoreForm/Select/Select';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import useCrews from 'shared/hooks/useCrews';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
};
const CrewSelector = ({gantt, task}: Props) => {
  const {updateValue, onKeyUp, onKeyDown, onClose} = useInlineSelectController(gantt);
  const {hasAnyAdminRole} = useCompanyWorkerRoles(task.projectId);
  const {data: crews, isLoading} = useCrews(task.projectId);

  const getSelectOptions = () => {
    const options = crews?.map((crew) => ({
      value: crew.crewId,
      label: crew.crewName,
    }));
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
  };

  const onChange = async (value: number) => {
    if (!value) {
      updateValue(task, 'wc_crew_id', null);
      return;
    }
    updateValue(task, 'wc_crew_id', value);
  };

  const getValue = () => {
    if (task.wc_crew_id === undefined) {
      return '';
    }
    return task.wc_crew_id;
  };

  if (!hasAnyAdminRole || !crews?.length) {
    return null;
  }

  return (
    <Select
      isClearable
      isLoading={isLoading}
      isSearchable
      value={getValue()}
      options={getSelectOptions()}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      menuIsOpen={true}
      closeMenuOnSelect={false}
      tabSelectsValue={false}
    />
  );
};

export default CrewSelector;
